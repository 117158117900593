import { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Navigation = (props) => {
  const location = useLocation();
  const [smallDevice, setSmallDevice] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (window.innerWidth > 1023) setSmallDevice(false);
    else setSmallDevice(true);
  }, [window.innerWidth]);

  return (
    <nav
      className="woocommerce-MyAccount-navigation"
      style={{
        display: props.showMenu ? "block" : smallDevice ? "none" : "block",
      }}
    >
      <ul>
        <li
          className={
            "woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--dashboard" +
            (location.pathname == "/my-account" ? " is-active" : "")
          }
        >
          <Link to="/my-account">Dashboard</Link>
        </li>
        <li
          className={
            "woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--orders" +
            (location.pathname == "/my-account/orders" ? " is-active" : "")
          }
        >
          <Link to="/my-account/orders">Orders</Link>
        </li>
        <li
          className={
            "woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--edit-address" +
            ([
              "/my-account/addresses",
              "/my-account/edit-address/billing",
              "/my-account/edit-address/shipping",
            ].includes(location.pathname)
              ? " is-active"
              : "")
          }
        >
          <Link to="/my-account/addresses">Addresses</Link>
        </li>
        <li
          className={
            "woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--edit-account" +
            (location.pathname == "/my-account/account-details"
              ? " is-active"
              : "")
          }
        >
          <Link to="/my-account/account-details">Account details</Link>
        </li>
        <li
          className={
            "woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--wishlist" +
            (location.pathname == "/my-account/wishlist" ? " is-active" : "")
          }
        >
          <Link to="/wishlist">Wishlist</Link>
        </li>
        {/* <li className="woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--customer-logout">
                    <Link to="https://klbtheme.com/bacola/my-account/customer-logout/?_wpnonce=08927d8a78">Logout</Link>
                </li> */}
      </ul>
    </nav>
  );
};

export default Navigation;
