import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React from "react";
import { convertToSlug } from "../../utils/constant";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";

const Popup = ({
  show,
  setShow,
  modalTitle,
  content,
  setaddressId,
  confirmClicked,
  isAddress,
  isOk,
  addressNum,
  onClose,
  addressId,
  setaddressNum,
  addressData,
  title,
}) => {
  let data = addressData?.addresses;
  // data.push({
  //   address: addressData?.address,
  //   pincode: addressData?.pincode,
  //   state: addressData?.state,
  //   city: addressData?.city,
  //   isDefault: true,
  // });

  return (
    <Modal
      show={show}
      onHide={() => {
        if (!isAddress) {
          setShow(false);
        } else {
          onClose();
        }
      }}
      size={isAddress ? "lg" : "md"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {!isAddress ? (
        <>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              {title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>{modalTitle}</h4>
            <p>{content}</p>
          </Modal.Body>
        </>
      ) : (
        <>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {title}
            </Modal.Title>
          </Modal.Header>
          <div
            className="table-responsive order_table checkout  "
            style={{ height: "180px" }}
          >
            <table className="table no-border ">
              <tbody>
                <tr
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setaddressId(addressData?.state_id);
                  }}
                >
                  <td
                    // className="image product-thumbnail"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <input
                      class="form-check-input mb-5"
                      type="radio"
                      // checked={i?.isDefault}
                      checked={
                        Number(addressId) === Number(addressData?.state_id)
                      }
                      id="flexCheckChecked"
                      onChange={() => {
                        setaddressNum(0);
                      }}
                      style={{ width: "20px", height: "20px" }}
                      // checked
                    />
                  </td>
                  <td>
                    <h6 className=" mb-5">
                      <p
                        // target="_blank"
                        className="text-heading"
                        // to={
                        //   "/product/" +
                        //   addressData.item_id +
                        //   "/" +
                        //   convertToSlug(addressData.item_name) +
                        //   "/" +
                        //   true
                        // }
                      >
                        {addressData?.address} {addressData?.pincode} ,{" "}
                        {addressData?.city} ,{addressData?.state?.state}
                      </p>
                    </h6>
                  </td>
                  {/* <td>
                      <h6 className="text-muted pl-20 pr-20">x {i.quantity}</h6>
                    </td>
                    <td>
                      <h4 className="text-brand">${i.subtotal.toFixed(2)}</h4>
                    </td> */}
                </tr>
                {data?.map((i, index) => {
                  return (
                    <tr
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setaddressId(i?.state_id);
                      }}
                    >
                      <td
                        // className="image product-thumbnail"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <input
                          class="form-check-input mb-5"
                          type="radio"
                          checked={Number(addressId) === Number(i?.state_id)}
                          onChange={(e) => {
                            setaddressNum(index + 1);
                          }}
                          id="flexCheckChecked"
                          style={{ width: "20px", height: "20px" }}
                        />
                      </td>
                      <td>
                        <h6 className=" mb-5">
                          <p
                            // target=""
                            className="text-heading"
                          >
                            {i?.address} {i?.pincode} , {i?.city} ,
                            {i?.state?.state}
                          </p>
                        </h6>
                      </td>
                      {/* <td>
                      <h6 className="text-muted pl-20 pr-20">x {i.quantity}</h6>
                    </td>
                    <td>
                      <h4 className="text-brand">${i.subtotal.toFixed(2)}</h4>
                    </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {isAddress && (
            <Link
              to={
                "/my-account/add-address/billing?add=" +
                JSON.stringify({
                  address: "",
                  city: "",
                  state_id: "",
                  state_name: "",
                  pincode: "",
                  isAddAddress: true,
                })
              }
              className="pb-3 p-2"
              style={{ textAlign: "end" }}
            >
              <h6 className="text-brand">Add Address ?</h6>
            </Link>
          )}
        </>
      )}
      {!isOk ? (
        <Modal.Footer>
          <Button className={" "} onClick={confirmClicked}>
            {isAddress ? "Update" : "Yes"}
          </Button>

          <Button onClick={() => setShow(false)}>
            {isAddress ? "Close" : "No"}
          </Button>
        </Modal.Footer>
      ) : (
        <Modal.Footer>
          <Button className={" "} onClick={confirmClicked}>
            Ok
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default Popup;
