import { useState } from "react";
import { toast } from "react-toastify";
import { sendContactMessage } from "../../services/ContactService";

const Contact = () => {
  const [contact, setContact] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleInputChange = (e) => {
    let { name, value } = e.target;

    setContact({ ...contact, [name]: value });
  };

  const handleSubmit = async () => {
    if (validateInput()) {
      await sendContactMessage(contact)
        .then((response) => {
          toast("Message Sent Successfully", { type: "success" });

          setContact({
            name: "",
            email: "",
            subject: "",
            message: "",
          });
        })
        .catch((error) => {
          console.log("Error", error.response);
          if (error.response.status == 422)
            toast(error.response.data.error, { type: "error" });
          else if (error.response.status == 500)
            toast(error.response.data.message, { type: "error" });
          else toast("Something went wrong", { type: "error" });
        });
    }
  };

  const validateInput = () => {
    if (contact.name == "") {
      toast("Please enter name.", {
        type: "error",
      });
      return false;
    }
    if (contact.email == "") {
      toast("Please enter email.", {
        type: "error",
      });
      return false;
    }
    if (contact.subject == "") {
      toast("Please enter subject.", {
        type: "error",
      });
      return false;
    }

    return true;
  };

  return (
    <main id="main" className="site-primary">
      <div className="site-content">
        <div className="homepage-content">
          <div
            data-elementor-type="wp-page"
            data-elementor-id={1685}
            className="elementor elementor-1685"
          >
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-a65fc9f elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="a65fc9f"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-extended">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e60227a"
                  data-id="e60227a"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <section
                      className="elementor-section elementor-inner-section elementor-element elementor-element-f536dd6 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-id="f536dd6"
                      data-element_type="section"
                    >
                      <div className="elementor-container elementor-column-gap-no">
                        <div
                          className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-aa801f7"
                          data-id="aa801f7"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-4cfc479 elementor-widget elementor-widget-heading"
                              data-id="4cfc479"
                              data-element_type="widget"
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <h1 className="elementor-heading-title elementor-size-default">
                                  Get In Touch
                                </h1>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-inner-section elementor-element elementor-element-c431c4d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-id="c431c4d"
                      data-element_type="section"
                    >
                      <div className="elementor-container elementor-column-gap-no">
                        <div
                          className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-101c476"
                          data-id="101c476"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-54306c4 elementor-widget elementor-widget-heading"
                              data-id="54306c4"
                              data-element_type="widget"
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <p className="elementor-heading-title elementor-size-default">
                                  Have any questions or queries? We'd love to
                                  hear from you.
                                </p>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-f9ec84e elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="f9ec84e"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-extended">
                <div
                  className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-6e6ebae"
                  data-id="6e6ebae"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-6710b9c elementor-widget elementor-widget-bacola-icon-box"
                      data-id="6710b9c"
                      data-element_type="widget"
                      data-widget_type="bacola-icon-box.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="klb-icon-box contact-icon">
                          <i className="klbth-icon-location" />
                          <h5 className="entry-title">
                            7 PROGRESS STREET,SUITE #104,EDISON,NJ-08820,USA
                          </h5>
                          <p>Here we located at</p>
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-e39b88c"
                  data-id="e39b88c"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-9c12b9f elementor-widget elementor-widget-bacola-icon-box"
                      data-id="9c12b9f"
                      data-element_type="widget"
                      data-widget_type="bacola-icon-box.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="klb-icon-box contact-icon">
                          <i className="klbth-icon-phone-call" />
                          <h5 className="entry-title">+1 908 251 5039</h5>
                          <p>For collaborations, contact us</p>
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-d839599"
                  data-id="d839599"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-075da15 elementor-widget elementor-widget-bacola-icon-box"
                      data-id="075da15"
                      data-element_type="widget"
                      data-widget_type="bacola-icon-box.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="klb-icon-box contact-icon">
                          <i className="klbth-icon-mail" />
                          <h5 className="entry-title">
                            allinonedistributionllc@gmail.com
                          </h5>
                          <p> Send your inquiries </p>
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-4ed59ca elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="4ed59ca"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-extended">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ffbccf2"
                  data-id="ffbccf2"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-cfe8f0f elementor-widget elementor-widget-bacola-contact-form-7"
                      data-id="cfe8f0f"
                      data-element_type="widget"
                      data-widget_type="bacola-contact-form-7.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="klb-contact-form contact-form-wrapper">
                          <div className="form-wrapper">
                            <div className="contact-header">
                              <h2>Send Us</h2>
                              <p>
                                Please use this form to contact us and we will
                                get back to you as soon as posible.
                              </p>
                            </div>
                            {/* contact-header */}
                            <div
                              role="form"
                              className="wpcf7"
                              id="wpcf7-f1737-p1685-o1"
                              lang="en-US"
                              dir="ltr"
                            >
                              <div className="screen-reader-response">
                                <p
                                  role="status"
                                  aria-live="polite"
                                  aria-atomic="true"
                                />{" "}
                                <ul />
                              </div>
                              <form
                                className="wpcf7-form init"
                                noValidate="novalidate"
                                data-status="init"
                              >
                                <div className="row">
                                  <div className="col-12 col-lg-6">
                                    <label> Your name * </label>
                                    <br />
                                    <span
                                      className="wpcf7-form-control-wrap"
                                      data-name="your-name"
                                    >
                                      <input
                                        type="text"
                                        name="name"
                                        value={contact.name}
                                        onChange={(e) => handleInputChange(e)}
                                        size={40}
                                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                        aria-required="true"
                                        aria-invalid="false"
                                      />
                                    </span>
                                  </div>
                                  <div className="col-12 col-lg-6">
                                    <label> Your email *</label>
                                    <br />
                                    <span
                                      className="wpcf7-form-control-wrap"
                                      data-name="your-email"
                                    >
                                      <input
                                        type="email"
                                        name="email"
                                        value={contact.email}
                                        onChange={(e) => handleInputChange(e)}
                                        size={40}
                                        className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                        aria-required="true"
                                        aria-invalid="false"
                                      />
                                    </span>
                                  </div>
                                  <div className="col-12 col-lg-12">
                                    <label> Subject * </label>
                                    <br />
                                    <span
                                      className="wpcf7-form-control-wrap"
                                      data-name="your-subject"
                                    >
                                      <input
                                        type="text"
                                        name="subject"
                                        value={contact.subject}
                                        onChange={(e) => handleInputChange(e)}
                                        size={40}
                                        className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                        aria-required="true"
                                        aria-invalid="false"
                                      />
                                    </span>
                                  </div>
                                  <div className="col-12 col-lg-12">
                                    <label> Your message </label>
                                    <br />
                                    <span
                                      className="wpcf7-form-control-wrap"
                                      data-name="your-message"
                                    >
                                      <textarea
                                        name="message"
                                        cols={40}
                                        rows={10}
                                        className="wpcf7-form-control wpcf7-textarea"
                                        aria-invalid="false"
                                        value={contact.message}
                                        onChange={(e) => handleInputChange(e)}
                                      />
                                    </span>
                                  </div>
                                  <div className="col-12 col-lg-12">
                                    <input
                                      type="button"
                                      value="Send Message"
                                      onClick={() => handleSubmit()}
                                      className="wpcf7-form-control has-spinner wpcf7-submit"
                                    />
                                    <span className="wpcf7-spinner" />
                                  </div>
                                </div>
                                <div
                                  className="wpcf7-response-output"
                                  aria-hidden="true"
                                />
                              </form>
                            </div>
                          </div>
                          {/* form-wrapper */}
                        </div>
                        {/* contact-form-wrapper */}{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        {/* homepage-content */}
      </div>
      {/* site-content */}
    </main>
  );
};

export default Contact;
