import axios from "axios";
import { handleAuthFailure } from "../utils/constant";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
let token = await localStorage.getItem("token");
let headers = {
  Authorization: `Bearer ${token}`,
};

const retriveItems = async (data) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        `/customer/items?category=${data.category}&brands=${data.brands}&per_page=${data.per_page}&page=${data.page}&min_price=${data.min_price}&max_price=${data.max_price}`,
        { headers: headers }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const retriveSearchItems = async (query, page = 1, per_page = 10) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        `/customer/search-items?query=${query}&page=${page}&per_page=${per_page}`,
        { headers: headers }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const retriveRelatedItems = async (category_id, item_id) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        `/customer/related-items?category_id=${category_id}&except_item=${item_id}`,
        { headers: headers }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const retriveHomePageItems = async (data) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
    // "Content-Type": "multipart/form-data",
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(
        "/customer/home-page-items",
        {},
        {
          headers: headers,
        }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const retriveCategorywiseItems = async (data) => {
  return new Promise(function (resolve, reject) {
    axios
      .get("customer/getitemByCat", {}, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const sliderImages = async (data) => {
  return new Promise(function (resolve, reject) {
    axios
      .get("/customer/sliders", { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const discountImages = async (data) => {
  return new Promise(function (resolve, reject) {
    axios
      .get("/customer/home-page-image", { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const retrivePromotedItems = async () => {
  return new Promise(function (resolve, reject) {
    axios
      .get("/customer/promoted-items", { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const retriveFrequentlyOrderedItems = async () => {
  return new Promise(function (resolve, reject) {
    axios
      .get("/customer/frequently-order-items", { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const addItemToWishlist = async (item_ids) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
    // "Content-Type": "multipart/form-data",
  };

  return new Promise(function (resolve, reject) {
    axios
      .post(`/customer/add-wishlist`, item_ids, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const retriveItem = async (item_id) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/customer/items/${item_id}`, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const retriveItemByIds = async (item_ids) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      // .get(`/customer/items-by-ids?ids=${item_ids}`)
      .post(
        `/customer/wishlist-list`,
        {},
        {
          headers,
        }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const wishlistClearApi = async (item_ids) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .post(`/customer/remove-wishlist`, item_ids, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const retriveRecentViewedItems = async (item_ids, except_id) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        `/customer/recent-viewed-items?ids=${item_ids}&except_id=${except_id}`,
        { headers: headers }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const addItemToCart = async (item_ids, quantity = 1) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .get(`/customer/add-to-cart?item_id=${item_ids}&quantity=${quantity}`, {
        headers: headers,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const addSelectedItemToCart = async (item_ids, quantity = 1) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .get(
        `/customer/add-selected-to-cart?item_ids=${item_ids}&quantity=${quantity}`,
        { headers: headers }
      )
      // .post(
      //   `/customer/add-selected-to-cart?quantity=${quantity}`,
      //   { item_ids: item_ids },
      //   { headers: headers }
      // )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

export {
  retriveItems,
  retriveItem,
  sliderImages,
  retriveHomePageItems,
  retriveCategorywiseItems,
  retriveRelatedItems,
  retriveItemByIds,
  addItemToWishlist,
  retriveRecentViewedItems,
  wishlistClearApi,
  addItemToCart,
  addSelectedItemToCart,
  discountImages,
  retrivePromotedItems,
  retriveFrequentlyOrderedItems,
  retriveSearchItems,
};
