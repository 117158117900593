import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { sliderImages } from "../../services/ItemService";
import { decodeHTMLEntities } from "../../utils/constant";
import _ from "lodash";

const Banner = () => {
  // const [bannerTranslate, setBannerTranslate] = useState(0);
  const [imagesData, setimagesData] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  let widthOfPage = window.innerWidth;

  useEffect(() => {
    getImages();
  }, []);

  let hightOfBanner =
    widthOfPage > 1000 ? "550px" : widthOfPage > 580 ? "350px" : "150px";

  const getImages = async () => {
    await sliderImages()
      .then((response) => {
        if (response.data.data) {
          let data = response.data.data;
          setisLoading(false);
          setimagesData(data);
        }
      })
      .catch((error) => {
        setisLoading(false);
        console.log("Error", error);
      });
  };

  let ele = document?.getElementById("no_right_click");
  ele?.addEventListener("contextmenu", (ev) => {
    ev?.preventDefault(); // this will prevent browser default behavior
  });

  useEffect(() => {
    widthOfPage = window.innerWidth;
  }, [window.innerWidth]);

  const properties = {
    prevArrow: (
      <button
        //  style={{ ...buttonStyle }}
        className="buttonStyle"
        style={{
          padding: "10px",
          marginBottom: widthOfPage > 800 ? "93px" : "30px",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="#fff"
        >
          <path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z" />
        </svg>
      </button>
    ),
    nextArrow: (
      <button
        className="buttonStyle"
        style={{
          padding: "10px",
          marginBottom: widthOfPage > 800 ? "93px" : "30px",
        }}

        // style={{ ...buttonStyle }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="#fff"
        >
          <path d="M512 256L270 42.6v138.2H0v150.6h270v138z" />
        </svg>
      </button>
    ),
  };

  const images = [
    "/images/newyear1.png",
    "/images/newyear2.png",
    "/images/cigarBanner01.png",
    "/images/cigarBanner02.png",
    "/images/cigarBanner03.png",
  ];



  return (
    <div
      // className="elementor-section elementor-top-section elementor-element elementor-element-384d7d2 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
      // data-id="384d7d2"
      id="no_right_click"
      // data-element_type="section"
    >
      {/* <div className="elementor-container elementor-column-gap-no">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b4a0bb1"
          data-id="b4a0bb1"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-f643bef elementor-widget elementor-widget-bacola-home-slider"
              data-id="f643bef"
              data-element_type="widget"
              data-widget_type="bacola-home-slider.default"
            >
              <div className="elementor-widget-container">
                <div className="site-module module-slider full-width wide ">
                  <div className="module-body">
                    <div className="slider-wrapper slider-loaded">
                      <svg
                        className="preloader"
                        width="65px"
                        height="65px"
                        viewBox="0 0 66 66"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          className="path"
                          fill="none"
                          strokeWidth="6"
                          strokeLinecap="round"
                          cx="33"
                          cy="33"
                          r="30"
                        ></circle>
                      </svg>
                      <div
                        className="site-slider slick-initialized slick-slider"
                        data-slideshow="1"
                        data-slidespeed="1200"
                        data-autoplay="false"
                        data-autospeed=""
                        data-dots=""
                        data-arrows="true"
                      >
                        <button
                          className="slick-prev slick-arrow"
                          aria-label="Previous"
                          type="button"
                          onClick={() => {
                            if (bannerTranslate >= 0) {
                              setBannerTranslate(0);
                            } else {
                              setBannerTranslate(bannerTranslate + widthOfPage);
                            }
                          }}
                        >
                          Previous
                        </button>
                        <div className="slick-list draggable">
                          <div
                            className="slick-track add-trans"
                            style={{
                              opacity: "1",
                              width: "5615px",
                              transform: `translate3d(${bannerTranslate}px, 0px, 0px)`,
                            }}
                          >
                            <div
                              className="slick-slide slick-current slick-active"
                              style={{ width: widthOfPage }}
                              data-slick-index="0"
                              aria-hidden="false"
                              tabIndex="0"
                            >
                              <div className="slider-item">
                                <div className="image-wrapper">
                                  <img
                                    src="/images/cigarBanner01.png"
                                    alt="bacola"
                                  />
                                </div>
                                <Link
                                  href=""
                                  className="overlay-link"
                                  tabIndex="0"
                                ></Link>
                              </div>
                            </div>
                            <div
                              className="slick-slide"
                              style={{ width: widthOfPage }}
                              data-slick-index="1"
                              aria-hidden="true"
                              tabIndex="-1"
                            >
                              <div className="slider-item">
                                <div className="image-wrapper">
                                  <img
                                    src="/images/cigarBanner02.png"
                                    alt="bacola"
                                  />
                                </div>
                                <Link
                                  href=""
                                  className="overlay-link"
                                  tabIndex="-1"
                                ></Link>
                              </div>
                            </div>
                            <div
                              className="slick-slide slick-cloned"
                              style={{ width: "1347px" }}
                              data-slick-index="2"
                              id=""
                              aria-hidden="true"
                              tabIndex="-1"
                            >
                              <div className="slider-item">
                                <div className="image-wrapper">
                                  <img
                                    src="/images/cigarBanner03.png"
                                    alt="bacola"
                                  />
                                </div>
                                <a
                                  href="/product/112/all-natural-italian-style-chicken-meatballs/"
                                  className="overlay-link"
                                  tabIndex="-1"
                                ></a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button
                          className="slick-next slick-arrow"
                          aria-label="Next"
                          type="button"
                          onClick={() => {
                            if (
                              bannerTranslate <= -1500 ||
                              0 - (4 - 1) * widthOfPage > bannerTranslate
                            ) {
                              setBannerTranslate(0);
                            } else {
                              setBannerTranslate(bannerTranslate - widthOfPage);
                            }
                          }}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {!isLoading && (
        <>
          {!_.isEmpty(imagesData) ? (
            <Slide transitionDuration={2000} autoplay={false} {...properties}>
              {imagesData.map(
                (image) => (
                  // widthOfPage > 800 ? (
                  <div
                    style={{
                      backgroundImage: `url(${
                        process.env.REACT_APP_BASE_URL +
                        "/storage/sliderimages/" +
                        image?.image
                      })`,
                      // backgroundImage: `url(${image})`,
                      objectFit: "cover",
                      backgroundSize: "100%",
                      // position: "fixed",
                      width: "100%",
                      height: hightOfBanner,
                      flex: 1,
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                )
                // ) : (
                //   <div
                //     style={{
                //       margin: "auto",
                //       flex: 1,
                //       display: "flex",
                //       justifyContent: "center",
                //       backgroundColor: "black",
                //     }}
                //   >
                //     <img
                //       // src={
                //       //   process.env.REACT_APP_BASE_URL +
                //       //   "/storage/sliderimages/" +
                //       //   image?.image
                //       // }
                //       src={image}
                //       alt=""
                //       style={{
                //         objectFit: "contain",
                //         height: 380,
                //         // border: "#fff 10px",
                //         // color: "#911",
                //         // backgroundImage: `url(${image})`,
                //       }}
                //     />
                //   </div>
                // )
              )}
            </Slide>
          ) : (
            <Slide transitionDuration={2000} autoplay={false} {...properties}>
              {images.map((image) => (
                <div
                  style={{
                    // backgroundImage: `url(${
                    //   process.env.REACT_APP_BASE_URL +
                    //   "/storage/sliderimages/" +
                    //   image?.image
                    // })`,
                    backgroundImage: `url(${image})`,
                    objectFit: "cover",
                    backgroundSize: "100%",
                    // position: "fixed",
                    width: "100%",
                    height: hightOfBanner,
                    flex: 1,
                    backgroundRepeat: "no-repeat",
                  }}
                />
              ))}
            </Slide>
          )}
        </>
      )}
    </div>
  );
};

export default Banner;

/* <img
  src={image}
  alt=""
  style={{
    objectFit: "contain",
    flex: 1,
    width: "100%",
    height: "100%",
    // border: "#fff 10px",
    // color: "#911",
    // backgroundImage: `url(${image})`,
  }}
/> */
