import { useContext, useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  createAccount,
  login,
  verifyLinkAPI,
} from "../../services/AuthService";
import { AppContext } from "../../utils/AppProvider";

const CreateAccount = () => {
  const [state, setState] = useState({
    username: "",
    password: "",
    confirmPassword: "",
  });
  const { setIsAuthenticated } = useContext(AppContext);

  const navigate = useNavigate();
  const params = useParams();

  const [passwordType, setpasswordType] = useState(false);
  const [confirmPasswordType, setconfirmPasswordType] = useState(false);
  const [verified, setverified] = useState(false);
  const [id, setid] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    verifyLink();
  }, []);

  const verifyLink = async () => {
    await verifyLinkAPI(params?.token)
      .then((response) => {
        setid(response?.data?.data?.id);
      })
      .catch((error) => {
        setverified(true);
        if (error?.response?.status === 422)
          toast(error.response.data.error, { type: "error" });
        else if (error?.response?.status === 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateInput()) {
      await createAccount({ ...state, id })
        .then((response) => {
          toast("Created Account Successfully", { type: "success" });
          navigate("/");
        })
        .catch((error) => {
          if (error?.response?.status === 422)
            toast(error.response.data.error, { type: "error" });
          else if (error?.response?.status === 500)
            toast(error.response.data.message, { type: "error" });
          else toast("Something went wrong", { type: "error" });
        });
    }
  };

  const validateInput = () => {
    const usernameRegex = /^[a-zA-Z0-9]*$/;

    if (state.username == "") {
      toast("Please enter Username.", {
        type: "error",
      });
      return false;
    } else if (!usernameRegex.test(state.username)) {
      toast(
        "Username should not be contain any speacial character or any space.",
        {
          type: "error",
        }
      );
      return false;
    } else if (
      state?.username?.trim()?.length < 6 ||
      state?.username?.trim()?.length > 12
    ) {
      toast("Username Must be between 6 to 12 characters long.", {
        type: "error",
      });
      return false;
    }

    if (state.password == "") {
      toast("Please enter password.", {
        type: "error",
      });
      return false;
    }

    if (state.confirmPassword == "") {
      toast("Please enter password.", {
        type: "error",
      });
      return false;
    }

    if (state.confirmPassword !== state.password) {
      toast("Password and Confirm Password should be the same.", {
        type: "error",
      });
      return false;
    }

    return true;
  };

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  return (
    <main id="main" className="site-primary">
      <div className="site-content">
        <div className="homepage-content">
          <div className="shop-content my-account-page">
            <div className="container">
              <nav className="woocommerce-breadcrumb">
                <ul>
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li>Create account</li>
                </ul>
              </nav>
              {!verified ? (
                <div className="woocommerce">
                  <div className="woocommerce-notices-wrapper"></div>

                  <div className="site-login" style={{ marginTop: "0px" }}>
                    <div className="site-login-container">
                      <div className="site-login-overflow">
                        <ul className="login-page-tab">
                          <li>
                            <a className="active">Create Account</a>
                          </li>
                        </ul>
                        <div
                          id="customer_login"
                          className="login-form-container"
                        >
                          <div className="login-form">
                            <form
                              className="woocommerce-form woocommerce-form-login login"
                              method="post"
                            >
                              <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                                <label htmlFor="username">
                                  Username &nbsp;
                                  <span className="required">*</span>
                                </label>
                                <input
                                  onKeyDown={(e) => _handleKeyDown(e)}
                                  onChange={(e) => handleInputChange(e)}
                                  type="text"
                                  className="woocommerce-Input woocommerce-Input--text input-text"
                                  name="username"
                                  id="username"
                                  autoComplete="username"
                                  value={state.username}
                                />
                              </p>
                              <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                                <label htmlFor="password">
                                  Password&nbsp;
                                  <span className="required">*</span>
                                </label>
                                <span className="password-input">
                                  <input
                                    onKeyDown={(e) => _handleKeyDown(e)}
                                    onChange={(e) => handleInputChange(e)}
                                    className="woocommerce-Input woocommerce-Input--text input-text"
                                    type={passwordType ? "text" : "password"}
                                    value={state.password}
                                    name="password"
                                    id="password"
                                    autoComplete="current-password"
                                  />
                                  <i
                                    className={
                                      "show-password " +
                                      (passwordType
                                        ? "klbth-icon-circle-empty"
                                        : "klbth-icon-circle")
                                    }
                                    title="Show Password"
                                    onClick={() =>
                                      setpasswordType(!passwordType)
                                    }
                                  ></i>
                                </span>
                              </p>
                              <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                                <label htmlFor="password">
                                  Confirm Password&nbsp;
                                  <span className="required">*</span>
                                </label>
                                <span className="password-input">
                                  <input
                                    onKeyDown={(e) => _handleKeyDown(e)}
                                    onChange={(e) => handleInputChange(e)}
                                    className="woocommerce-Input woocommerce-Input--text input-text"
                                    type={
                                      confirmPasswordType ? "text" : "password"
                                    }
                                    value={state.confirmPassword}
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    autoComplete="current-password"
                                  />
                                  <i
                                    className={
                                      "show-password " +
                                      (confirmPasswordType
                                        ? "klbth-icon-circle-empty"
                                        : "klbth-icon-circle")
                                    }
                                    title="Show Password"
                                    onClick={() =>
                                      setconfirmPasswordType(
                                        !confirmPasswordType
                                      )
                                    }
                                  ></i>
                                </span>
                              </p>

                              <p
                                className="form-row"
                                style={{ marginTop: "40px" }}
                              >
                                <button
                                  type="button"
                                  onClick={(e) => handleSubmit(e)}
                                  className="woocommerce-button button woocommerce-form-login__submit "
                                  name="login"
                                  value="Log in"
                                >
                                  Log in
                                </button>
                              </p>
                              {/* <p className="woocommerce-LostPassword lost_password">
                              <Link to="/my-account/lost-password">
                                Lost your password?
                              </Link>
                            </p> */}
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <h1 style={{ textAlign: "center", marginTop: "51px" }}>
                  The link has been expired.
                </h1>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default CreateAccount;
