import { Link } from "react-router-dom";

const FooterBottom = () => {
  return (
    <div className="footer-bottom border-enable">
      <div className="container">
        <div
          className="site-copyright"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <h5 className="text-black">
            Affilate partner and wholesale distributors
          </h5>
          <Link to="/" title="Bacola – Grocery Market and Food Theme">
            <img
              className="desktop-logo hide-mobile img-fluid"
              src="/images/logoAllInOne.jpeg"
              alt="Bacola – Grocery Market and Food Theme"
              // width={50}
              // style={{height:'auto'}}
              style={{ width: "150px", height: "50px", margin: "10px" }}
            />
          </Link>
          <p>
            Copyright {new Date().getFullYear()} ©{" "}
            {process.env.REACT_APP_PROJECT}. All rights reserved. Powered by{" "}
            <a href="https://wa.me/+919376002929" target="_blank">
              <b>N2N</b>
            </a>{" "}
            Solution.
          </p>
        </div>
        {/* <div style={{marginLeft:'137px'}}>
                    middle style
                </div> */}

        <nav className="site-menu footer-menu">
          <ul
            id="menu-footer-menu"
            className="menu "
            style={{ marginBottom: "6px" }}
          >
            <li
              id="menu-item-1889"
              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-privacy-policy menu-item-1889"
            >
              {/* <Link to="/privacy-policy">Privacy Policy</Link> */}
            </li>
            <li
              id="menu-item-2066"
              className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2066"
            >
              {/* <Link to="/terms-and-conditions/">Terms and Conditions</Link> */}
            </li>
            {/* <li id="menu-item-1844"
                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-1844"><Link to="#/home-3/#">Cookie</Link></li> */}
          </ul>
          <span>
            <i
              className="klbth-icon-phone-call "
              style={{ marginRight: "5px" }}
            ></i>
            +1 908 251 5039
          </span>
          {/* <ul >

                    <span  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2066">
                   

                    </span>
                    </ul> */}
          <ul style={{ marginLeft: "-13px" }}>
            <li
              id="menu-item-2066"
              // className="menu-item menu-item-type-post_type menu-item-object-page menu-item-2066"
            >
              <Link to="#"> Working 9:00 AM - 6:00 PM (EST),Mon - Fri </Link>
            </li>
          </ul>
          <span>
            <i className="klbth-icon-mail " style={{ marginRight: "5px" }}></i>
            allinonedistributionllc@gmail.com
          </span>
        </nav>
        <div className="site-payments">
          <a href="#/home-3/#">
            {/* <img
                    src="/images/payments.jpg" alt="payment" /> */}
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterBottom;
