import React from "react";
import Slider from "react-slick";

export default function LogoScroll() {
  var settings = {
    // dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 6,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="p-5">
      <Slider {...settings}>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/logo/1.jpg"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/logo/2.png"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/logo/3.jpg"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/logo/4.jpg"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/logo/5.png"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/logo/6.png"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/logo/7.jpg"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/logo/8.png"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/logo/9.jpg"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/logo/10.jpg"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/logo/11.jpg"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/logo/12.png"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/logo/13.png"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/logo/14.jpg"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/logo/15.jpg"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/logo/16.jpg"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/logo/17.png"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/logo/18.png"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
        <div>
          <img
            className="p-2 bg-black"
            src="/images/logo/19.png"
            alt="Logo"
            style={{ width: "130px", height: "75px", margin: "10px" }}
          />
        </div>
      </Slider>
    </div>
  );
}
