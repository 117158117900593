import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import { Routes, Route, Navigate } from "react-router-dom";
import routes from "../routes/route.js";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Provider from "../utils/AppProvider";
import { useState } from "react";
import NotFound from "../components/NotFound/index.jsx";

let is_authenticated = "";
const Index = () => {
  const [flag, setFlag] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(async () => {
    is_authenticated = await localStorage.getItem("is_authenticated");
    let auth_url = [
      "/login",
      "/register",
      "/my-account/lost-password",
      "/my-account/reset-password",
      "/create-account/:token",
    ];
    if (
      is_authenticated == 1 &&
      (location.pathname == "/login" || location.pathname == "/register")
    ) {
      navigate("/");
    }
    // else if (
    //   (is_authenticated == 0 ||
    //     is_authenticated == null ||
    //     is_authenticated == undefined) &&
    //   !auth_url.includes(location.pathname)
    // ) {
    //   navigate("/login");
    // }
    return () => {};
  });

  return (
    <>
      <Provider>
        <Header></Header>
        <Routes>
          {routes.map((route, i) => {
            if (location.pathname == route.path && route.private === true) {
              if (localStorage.getItem("is_authenticated")) {
                return (
                  <Route
                    exact
                    path={route.path}
                    key={i}
                    element={<route.page.component />}
                  >
                    {" "}
                  </Route>
                );
              } else {
                <Navigate to="/login" />;
              }
            } else {
              return (
                <Route
                  exact
                  path={route.path}
                  key={i}
                  element={<route.page.component />}
                >
                  {" "}
                </Route>
              );
            }
          })}
          <Route path="*" element={<NotFound />}>
            {" "}
          </Route>
        </Routes>
        <Footer></Footer>
      </Provider>
    </>
  );
};

export default Index;
