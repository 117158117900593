import axios from "axios";
import { handleAuthFailure } from "../utils/constant";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const sendContactMessage = async (contact) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .post(
        "/customer/send-contact-message",
        { ...contact },
        { headers: headers }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

export { sendContactMessage };
