import axios from "axios";
import { handleAuthFailure } from "../utils/constant";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

let token = await localStorage.getItem("token");
let headers = {
  Authorization: `Bearer ${token}`,
};
const getAllStates = async () => {
  return new Promise(function (resolve, reject) {
    axios
      .get("/customer/states", { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const stateByIds = async (ids) => {
  return new Promise(function (resolve, reject) {
    axios
      .get(`/customer/states-by-ids?ids=${ids}`, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const getPOSStates = async (id = "") => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/customer/posstates?id=${id}`, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

export { getAllStates, stateByIds, getPOSStates };
