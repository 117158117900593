import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <nav className="canvas-menu canvas-primary vertical">
      <ul id="menu-menu-1" className="menu">
        <li className="dropdown  menu-item menu-item-type-custom menu-item-object-custom menu-item-home current-menu-ancestor current-menu-parent">
          <Link to="/">Home</Link>

          {/* <span className="menu-dropdown"><i className="klbth-icon-down-open-big"></i></span> */}
        </li>

        <li className=" menu-item menu-item-type-taxonomy menu-item-object-product_cat">
          <Link to="/product-category/premium-cigar">
            <img
              src="/images/cigar-icon.png"
              alt="cigar"
              style={{ height: "30px", width: "30px" }}
            />
            {/* <i
                        className="klbth-icon-meat"></i> */}
            Premium Cigars
          </Link>
        </li>
        <li className=" menu-item menu-item-type-taxonomy menu-item-object-product_cat">
          <Link to="/product-category/cigar">
            <img
              src="/images/cigar-icon.png"
              alt="cigar"
              style={{ height: "30px", width: "30px" }}
            />
            {/* <i
                        className="klbth-icon-forumbee"></i> */}
            Cigars
          </Link>
        </li>
        <li className=" menu-item menu-item-type-taxonomy menu-item-object-product_cat">
          <Link to="/product-category/cigarillos">
            <img
              src="/images/cigar-icon2.png"
              alt="cigar"
              style={{ height: "30px", width: "30px", margin: "5px" }}
            />
            {/* <i className="klbth-icon-biscuit"></i> */}
            Cigarillos
          </Link>
        </li>
        <li className=" menu-item menu-item-type-taxonomy menu-item-object-product_cat">
          <Link to="/product-category/tobacco-accessories">
            <i className="klbth-icon-wheat"></i>
            Tobacco Accessories
          </Link>
        </li>

        <li className=" menu-item menu-item-type-post_type menu-item-object-page">
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
