import { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addItemToCart,
  addSelectedItemToCart,
  retriveItemByIds,
  wishlistClearApi,
} from "../../services/ItemService";
import { convertToSlug, isLogin } from "../../utils/constant";
import Popup from "../Popup";

const Wishlist = () => {
  const [wishlistItems, setwishlistItems] = useState([]);
  const [confirmationPopup, setconfirmationPopup] = useState(false);
  const [selectedEvent, setselectedEvent] = useState(0);
  const [selectedCartEvent, setselectedCartEvent] = useState(0);
  const [confirmationCartPopup, setconfirmationCartPopup] = useState(false);
  const [selectedItems, setSelectedItem] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getWishlist();
  }, []);

  const getWishlist = async () => {
    retriveItemByIds()
      .then((response) => {
        setwishlistItems(response.data.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const clearWishlist = async () => {
    if (wishlistItems.length) {
      // await localStorage.removeItem("wishlist_items");

      let formdata = new FormData();

      formdata.append("id", "all");

      await wishlistClearApi(formdata)
        .then((response) => {
          // setWishlistQty(response?.data?.data);
          setselectedEvent(0);
          setconfirmationPopup(false);
          setwishlistItems([]);
          setSelectedItem([]);
          toast("Wishlist clear successfully", { type: "success" });
        })
        .catch((error) => {
          console.log("Error:", error);
        });

      // if (!cart) {
      //   toast("Wishlist clear successfully", { type: "success" });
      // }
    }
  };

  const toggleWishlist = async (item) => {
    let items = await localStorage.getItem("wishlist_items");
    if (items != null) {
      items = JSON.parse(items);
      let ids = items.map((item) => item["id"]);

      if (ids.includes(item.id)) {
        let wishlist_items = items.filter((_item) => _item.id != item.id);

        setwishlistItems(wishlist_items);
        localStorage.setItem("wishlist_items", JSON.stringify(wishlist_items));
        toast("Item Removed from Wishlist", { type: "success" });
      }
    }
  };

  const handleSelectItem = (e, item) => {
    if (e.target.checked) {
      setSelectedItem([...selectedItems, item]);
    } else {
      setSelectedItem(selectedItems.filter((i) => i.id != item.id));
    }
  };

  const clearSelected = async () => {
    let ids = selectedItems.map((i) => {
      return i.id;
    });

    // setwishlistItems(items);
    let formdata = new FormData();
    let selectedIds = "";
    if (ids.length > 0) {
      selectedIds = ids.join(",");
    }
    formdata.append("id", selectedIds);

    await wishlistClearApi(formdata)
      .then((response) => {
        // setWishlistQty(response?.data?.data);
        getWishlist();
        setselectedEvent(0);
        setconfirmationPopup(false);
        setSelectedItem([]);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const handleAddToCart = (item) => {
    if (isLogin()) {
      let item_id = item.id;
      addItemToCart(item_id)
        .then((response) => {
          let items = wishlistItems.filter((i) => i.id != item_id);
          setwishlistItems(items);
          localStorage.setItem("wishlist_items", JSON.stringify(items));
          toast("Item Added To Cart Successfully.", { type: "success" });
        })
        .catch((error) => {
          console.log("Error", error);
          if (error.response.status == 422)
            toast(error.response.data.message, { type: "error" });
          else if (error.response.status == 500)
            toast(error.response.data.message, { type: "error" });
          else toast("Something went wrong", { type: "error" });
        });
    } else {
      toast("Please login to add item to cart", { type: "error" });
    }
  };

  const addSelectedToCart = () => {
    let ids = selectedItems.map((i) => i.id);

    if (isLogin()) {
      addSelectedItemToCart(ids)
        .then((response) => {
          let items = wishlistItems.filter((i) => {
            if (!ids.includes(i.id)) {
              return i;
            }
          });

          setwishlistItems(items);
          setSelectedItem([]);
          localStorage.setItem("wishlist_items", JSON.stringify(items));
          toast("Item Added To Cart Successfully.", { type: "success" });
        })
        .catch((error) => {
          console.log("Error", error);
          if (error.response.status == 422)
            toast(error.response.data.message, { type: "error" });
          else if (error.response.status == 500)
            toast(error.response.data.message, { type: "error" });
          else toast("Something went wrong", { type: "error" });
        });
    } else {
      toast("Please login to add item to cart", { type: "error" });
    }
  };

  const addAllItemToCart = () => {
    let ids = wishlistItems.map((i) => i.id);

    if (isLogin()) {
      addSelectedItemToCart(ids)
        .then((response) => {
          setwishlistItems([]);
          setSelectedItem([]);
          localStorage.setItem("wishlist_items", JSON.stringify([]));
          toast("Item Added To Cart Successfully.", { type: "success" });
        })
        .catch((error) => {
          console.log("Error", error);
          if (error.response.status == 422)
            toast(error.response.data.message, { type: "error" });
          else if (error.response.status == 500)
            toast(error.response.data.message, { type: "error" });
          else toast("Something went wrong", { type: "error" });
        });
    } else {
      toast("Please login to add item to cart", { type: "error" });
    }
  };

  const eventTrigger = (event) => {
    if (event === 1) {
      clearSelected();
    } else if (event === 2) {
      clearWishlist();
    }
    setselectedEvent(0);
    setconfirmationPopup(false);
  };

  const cartEventTrigger = (event) => {
    if (event === 1) {
      addSelectedToCart();
    } else if (event === 2) {
      addAllItemToCart();
    }
    setselectedCartEvent(0);
    setconfirmationCartPopup(false);
  };

  return (
    <main id="main" className="site-primary">
      <div className="site-content">
        <div className="homepage-content">
          <div
            data-elementor-type="wp-page"
            data-elementor-id={633}
            className="elementor elementor-633"
          >
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-1922087b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="1922087b"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2658ff39"
                  data-id="2658ff39"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-214b7e48 elementor-widget elementor-widget-shortcode"
                      data-id="214b7e48"
                      data-element_type="widget"
                      data-widget_type="shortcode.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-shortcode">
                          <div className="tinv-wishlist woocommerce tinv-wishlist-clear">
                            <div className="tinv-header">
                              <h2> Wishlist </h2>
                            </div>
                            <form
                              autoComplete="off"
                              data-tinvwl_paged={1}
                              data-tinvwl_sharekey="b45fa3"
                            >
                              <table className="tinvwl-table-manage-list">
                                <thead>
                                  <tr>
                                    <th className="product-cb">
                                      {/* <input type="checkbox" className="global-cb" title="Select all for bulk action" /> */}
                                    </th>
                                    {/* <th className="product-remove" /> */}
                                    <th className="product-thumbnail">
                                      &nbsp;
                                    </th>
                                    <th className="product-name">
                                      <span className="tinvwl-full">
                                        Product Name
                                      </span>
                                      <span className="tinvwl-mobile">
                                        Product
                                      </span>
                                    </th>
                                    {isLogin() && (
                                      <th className="product-price">
                                        Unit Price
                                      </th>
                                    )}
                                    <th className="product-date">Date Added</th>
                                    <th className="product-stock">
                                      Stock Status
                                    </th>
                                    <th className="product-action">&nbsp;</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {wishlistItems.length > 0 ? (
                                    wishlistItems.map((item, index) => {
                                      return (
                                        <tr
                                          key={index}
                                          className="wishlist_item"
                                        >
                                          <td className="product-cb">
                                            <input
                                              type="checkbox"
                                              name="wishlist_pr[]"
                                              onClick={(e) => {
                                                handleSelectItem(e, item);
                                              }}
                                              checked={selectedItems.includes(
                                                item
                                              )}
                                              title="Select for bulk action"
                                            />{" "}
                                          </td>
                                          {/* <td className="product-remove">
                                            <button
                                              onClick={() =>
                                                toggleWishlist(item)
                                              }
                                              type="button"
                                              name="tinvwl-remove"
                                              value={4478}
                                              title="Remove"
                                            >
                                              <i className="ftinvwl ftinvwl-times" />
                                            </button>
                                          </td> */}
                                          <td className="product-thumbnail">
                                            <Link
                                              to={
                                                "/product/" +
                                                item.id +
                                                "/" +
                                                convertToSlug(item.item_name)
                                              }
                                            >
                                              <img
                                                width={90}
                                                height={90}
                                                src={
                                                  process.env
                                                    .REACT_APP_BASE_URL +
                                                  "/storage/products/" +
                                                  (item.image
                                                    ? item.image
                                                    : "no-image.png")
                                                }
                                                className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail"
                                                alt=""
                                                loading="lazy"
                                              />
                                            </Link>{" "}
                                          </td>
                                          <td className="product-name">
                                            <Link
                                              to={
                                                "/product/" +
                                                item.id +
                                                "/" +
                                                convertToSlug(item.item_name)
                                              }
                                            >
                                              {item.item_name}
                                            </Link>{" "}
                                          </td>
                                          {isLogin() && (
                                            <td className="product-price">
                                              {/* <del aria-hidden="true">
                                                '
                                                <span className="woocommerce-Price-amount amount">
                                                  <bdi>
                                                    <span className="woocommerce-Price-currencySymbol">
                                                      $
                                                    </span>
                                                    {item?.sales_price}
                                                  </bdi>
                                                </span>
                                                '
                                              </del>{" "} */}
                                              <ins>
                                                {isLogin() && (
                                                  <span className="woocommerce-Price-amount amount">
                                                    <bdi>
                                                      <span className="woocommerce-Price-currencySymbol">
                                                        $
                                                      </span>
                                                      {item.sales_price}
                                                    </bdi>
                                                  </span>
                                                )}
                                              </ins>{" "}
                                            </td>
                                          )}
                                          <td className="product-date">
                                            <time
                                              className="entry-date"
                                              dateTime="2022-11-24 08:57:03"
                                            >
                                              {item.wishlist_added_date}
                                            </time>{" "}
                                          </td>
                                          <td className="product-stock">
                                            <p className="stock in-stock">
                                              <span>
                                                <i className="ftinvwl ftinvwl-check" />
                                              </span>
                                              <span>
                                                {" "}
                                                {item.stock > 0
                                                  ? "In Stock"
                                                  : "Out of Stock"}{" "}
                                              </span>
                                            </p>{" "}
                                          </td>
                                          <td className="product-action">
                                            <button
                                              type="button"
                                              onClick={() =>
                                                handleAddToCart(item)
                                              }
                                              className="button alt"
                                              title="Add to Cart"
                                            >
                                              <i className="ftinvwl ftinvwl-shopping-cart" />
                                              <span className="tinvwl-txt">
                                                Add to Cart
                                              </span>
                                            </button>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td
                                        style={{ textAlign: "center" }}
                                        colSpan={8}
                                      >
                                        {" "}
                                        <h6> No Items Available </h6>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td colSpan={100}>
                                      {/* <div className="tinvwl-to-left look_in">
                                          <div className="tinvwl-input-group tinvwl-no-full">
                                            <input type="hidden" name="lists_per_page" defaultValue={10} id="tinvwl_lists_per_page" />
                                            <select name="product_actions" id="tinvwl_product_actions" className="tinvwl-break-input-filed form-control">
                                              <option value selected="selected">Actions</option>
                                              <option value="add_to_cart_selected">Add to Cart</option>
                                              <option value="remove_selected">Remove</option>
                                            </select>
                                            <span className="tinvwl-input-group-btn">
                                                <button type="submit" className="button" name="tinvwl-action-product_apply" value="product_apply" title="Apply Action">Apply <span className="tinvwl-mobile">Action</span>
                                                </button>
                                            </span>
                                          </div>
                                        </div> */}
                                      <div className="tinvwl-to-right look_in">
                                        <button
                                          type="button"
                                          className="button"
                                          disabled={wishlistItems.length <= 0}
                                          style={{
                                            opacity:
                                              wishlistItems.length <= 0
                                                ? "0.7"
                                                : "1",
                                          }}
                                          name="tinvwl-action-product_selected"
                                          value="product_selected"
                                          // onClick={() => clearSelected()}
                                          onClick={() => {
                                            setselectedEvent(1);
                                            setconfirmationPopup(true);
                                          }}
                                        >
                                          Remove Selected Items
                                        </button>
                                        <button
                                          type="button"
                                          className="button"
                                          disabled={wishlistItems.length <= 0}
                                          style={{
                                            opacity:
                                              wishlistItems.length <= 0
                                                ? "0.7"
                                                : "1",
                                          }}
                                          name="tinvwl-action-product_selected"
                                          value="product_selected"
                                          // onClick={() => clearWishlist()}
                                          onClick={() => {
                                            setconfirmationPopup(true);
                                            setselectedEvent(2);
                                          }}
                                        >
                                          Empty Wishlist
                                        </button>
                                        <button
                                          // onClick={() => addSelectedToCart()}
                                          onClick={() => {
                                            setconfirmationCartPopup(true);
                                            setselectedCartEvent(1);
                                          }}
                                          disabled={wishlistItems.length <= 0}
                                          style={{
                                            opacity:
                                              wishlistItems.length <= 0
                                                ? "0.7"
                                                : "1",
                                          }}
                                          type="button"
                                          className="button"
                                          name="tinvwl-action-product_selected"
                                          value="product_selected"
                                        >
                                          Add Selected to Cart
                                        </button>
                                        <button
                                          // onClick={() => addAllItemToCart()}
                                          onClick={() => {
                                            setconfirmationCartPopup(true);
                                            setselectedCartEvent(2);
                                          }}
                                          disabled={wishlistItems.length <= 0}
                                          style={{
                                            opacity:
                                              wishlistItems.length <= 0
                                                ? "0.7"
                                                : "1",
                                          }}
                                          type="button"
                                          className="button"
                                          name="tinvwl-action-product_all"
                                          value="product_all"
                                        >
                                          Add All to Cart
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </form>
                            {/* <div className="social-buttons">
                            <span>Share on</span>
                            <ul>
                              <li><a href="#https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fklbtheme.com%2Fbacola%2Fwishlist%2Fb45fa3%2F" className="social social-facebook " title="Facebook"><i className="ftinvwl ftinvwl-facebook" /></a></li>
                              <li><a href="#https://twitter.com/share?url=https%3A%2F%2Fklbtheme.com%2Fbacola%2Fwishlist%2Fb45fa3%2F" className="social social-twitter " title="Twitter"><i className="ftinvwl ftinvwl-twitter" /></a></li>
                              <li><a href="#http://pinterest.com/pin/create/button/?url=https%3A%2F%2Fklbtheme.com%2Fbacola%2Fwishlist%2Fb45fa3%2F&media=https%3A%2F%2Fklbtheme.com%2Fbacola%2Fwp-content%2Fuploads%2F2021%2F04%2Fproduct-image-58.jpg" className="social social-pinterest " title="Pinterest"><i className="ftinvwl ftinvwl-pinterest" /></a></li>
                              <li><a href="#https://api.whatsapp.com/send?text=https%3A%2F%2Fklbtheme.com%2Fbacola%2Fwishlist%2Fb45fa3%2F" className="social social-whatsapp " title="WhatsApp"><i className="ftinvwl ftinvwl-whatsapp" /></a></li>
                              <li><a href="#https://klbtheme.com/bacola/wishlist/b45fa3/" className="social social-clipboard " title="Clipboard"><i className="ftinvwl ftinvwl-clipboard" /></a></li>
                              <li><a href="mailto:?body=https%3A%2F%2Fklbtheme.com%2Fbacola%2Fwishlist%2Fb45fa3%2F" className="social social-email " title="Email"><i className="ftinvwl ftinvwl-email" /></a></li>
                            </ul>
                          </div> */}
                          </div>
                        </div>
                        <Popup
                          title={"Confirmation Popup"}
                          content={`Are you Sure ?`}
                          show={confirmationPopup}
                          setShow={setconfirmationPopup}
                          confirmClicked={(e) => {
                            eventTrigger(selectedEvent);
                          }}
                        />
                        <Popup
                          title={"Confirmation Popup"}
                          content={`Are you Sure ?`}
                          show={confirmationCartPopup}
                          setShow={setconfirmationCartPopup}
                          confirmClicked={(e) => {
                            cartEventTrigger(selectedCartEvent);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        {/* homepage-content */}
      </div>
      {/* site-content */}
    </main>
  );
};

export default Wishlist;
