import axios from "axios";
import { handleAuthFailure } from "../utils/constant";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const retriveAccountDetails = async () => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .get("/customer/account-details", { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

const retriveAddreses = async () => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .get("/customer/addresses", { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
         handleAuthFailure(error, reject);
      });
  });
};

const updateBillingAddress = async (data) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .post(
        "/customer/update-billing-address",
        { ...data },
        { headers: headers }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
          handleAuthFailure(error, reject);
      });
  });
};

const updateShippingAddress = async (data) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .post(
        "/customer/update-shipping-address",
        { ...data },
        { headers: headers }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
          handleAuthFailure(error, reject);
      });
  });
};

const saveAccountDetails = async (data) => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise(function (resolve, reject) {
    axios
      .post("/customer/save-account-details", { ...data }, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
          handleAuthFailure(error, reject);
      });
  });
};
const getCustomerCategories = async () => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get(`/customer/pos-customer-category`, { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
         handleAuthFailure(error, reject);
      });
  });
};
export {
  retriveAddreses,
  updateBillingAddress,
  updateShippingAddress,
  retriveAccountDetails,
  saveAccountDetails,
  getCustomerCategories,
};
