import { useContext } from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addItemToCart,
  addItemToWishlist,
  retriveSearchItems,
} from "../../services/ItemService";
import { AppContext } from "../../utils/AppProvider";
import { convertToSlug, isLogin } from "../../utils/constant";

let timeout;
const Search = () => {
  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [priceSort, setPriceSort] = useState("");
  const [wishlistItems, setwishlistItems] = useState([]);
  const [links, setLinks] = useState([]);
  const { search, setCartQty, cart_qty } = useContext(AppContext);

  useEffect(() => {
    setPage(1);
    setPerPage(10);
    setPriceSort("");
    retriveItems();
  }, [search]);

  useEffect(() => {
    retriveItems();
  }, [page, perPage]);

  useEffect(() => {
    getWishlist();
  }, []);

  useEffect(() => {
    setPriceSort("");
    setPage(1);
  }, [perPage]);

  useEffect(() => {
    // asceding Order
    if (priceSort == "price") {
      const sorted_items = [...items].sort(
        (a, b) => a.sales_price - b.sales_price
      );
      setItems(sorted_items);
    }

    // Desceding Order
    if (priceSort == "price-desc") {
      const sorted_items = [...items].sort(
        (a, b) => b.sales_price - a.sales_price
      );
      setItems(sorted_items);
    }
  }, [priceSort]);

  const getWishlist = async () => {
    let items = await localStorage.getItem("wishlist_items");

    if (items != null) {
      items = JSON.parse(items);
      let wishlist_item_ids = items.map((_item) => {
        return _item.id;
      });
      setwishlistItems(wishlist_item_ids);
    }
  };

  const retriveItems = () => {
    clearTimeout(timeout);
    timeout = setTimeout(function () {
      retriveSearchItems(search, page, perPage)
        .then((response) => {
          if (response?.data) {
            setItems(response.data.data?.data);
            setLinks(response.data.data?.links);
            clearTimeout(timeout);
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }, 300);
  };

  // const toggleWishlist = async (item) => {
  //   let items = await localStorage.getItem("wishlist_items");

  //   let date = new Date();
  //   date =
  //     date.toLocaleString("default", { month: "long" }) +
  //     " " +
  //     date.getDate() +
  //     ", " +
  //     date.getFullYear();

  //   if (items != null) {
  //     items = JSON.parse(items);
  //     let ids = items.map((item) => item["id"]);

  //     if (ids.includes(item.id)) {
  //       let wishlist_items = items.filter((_item) => _item.id != item.id);
  //       let wishlist_item_ids = wishlist_items.map((_item) => {
  //         return _item.id;
  //       });

  //       setwishlistItems(wishlist_item_ids);
  //       localStorage.setItem("wishlist_items", JSON.stringify(wishlist_items));
  //       toast("Item Removed from Wishlist", { type: "success" });
  //     } else {
  //       item["wishlist_added_date"] = date;
  //       items.push(item);
  //       setwishlistItems([...wishlistItems, item.id]);
  //       localStorage.setItem("wishlist_items", JSON.stringify(items));
  //       toast("Item added into Wishlist", { type: "success" });
  //     }
  //   } else {
  //     let temp = [];
  //     item["wishlist_added_date"] = date;
  //     temp.push(item);
  //     setwishlistItems([item.id]);
  //     localStorage.setItem("wishlist_items", JSON.stringify(temp));

  //     toast("Item added into Wishlist", { type: "success" });
  //   }
  // };

  const wishlistToggle = async (item, e) => {
    e?.preventDefault();
    let formdata = new FormData();
    formdata.append("item_id", item);

    await addItemToWishlist(formdata)
      .then((response) => {
        let arr = [];
        for (let a of items) {
          let obj = a;
          if (a?.id === item) {
            obj = { ...a, wishlist: a?.wishlist ? 0 : 1 };
          }
          arr?.push(obj);
        }

        setItems(arr);

        toast(response?.data?.message, { type: "success" });
      })
      .catch((error) => {
        if (error?.response?.status == 422)
          toast(error.response.data.error, { type: "error" });
        else if (error?.response?.status == 500)
          toast(error.response.data.message, { type: "error" });
        else toast("Something went wrong", { type: "error" });
      });
  };

  const handleAddToCart = (item) => {
    if (isLogin()) {
      let item_id = item.id;
      addItemToCart(item_id)
        .then((response) => {
          setCartQty(parseInt(cart_qty) + 1);
          toast("Item Added To Cart Successfully.", { type: "success" });
        })
        .catch((error) => {
          console.log("Error", error);
          if (error.response.status == 422)
            toast(error.response.data.message, { type: "error" });
          else if (error.response.status == 500)
            toast(error.response.data.message, { type: "error" });
          else toast("Something went wrong", { type: "error" });
        });
    } else {
      toast("Please login to add item to cart", { type: "error" });
    }
  };

  return (
    <main id="main" className="site-primary">
      <div className="site-content">
        <div className="homepage-content">
          <div className="container">
            <nav className="woocommerce-breadcrumb">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Shop</li>
              </ul>
            </nav>
            <div className="row content-wrapper sidebar-left">
              <div className="col-12 col-md-12 col-lg-12 content-primary">
                {items.length > 0 ? (
                  <>
                    <div className="before-shop-loop">
                      <div className="shop-view-selector"></div>
                      <div className="mobile-filter">
                        <Link href="" className="filter-toggle">
                          <i className="klbth-icon-filter" />
                          <span>Filter Products</span>
                        </Link>
                      </div>
                      {/* For get orderby from loop */}
                      <form
                        className="woocommerce-ordering product-filter"
                        method="get"
                      >
                        <span className="orderby-label hide-desktop">
                          Sort by
                        </span>
                        <select
                          value={priceSort}
                          onChange={(e) => setPriceSort(e.target.value)}
                          name="orderby"
                          className="orderby filterSelect"
                          aria-label="Shop order"
                          data-select2-id="1"
                          tabIndex="-1"
                          aria-hidden="true"
                        >
                          <option value="">Sort By Price</option>
                          <option value="price">
                            Sort by price: low to high
                          </option>
                          <option value="price-desc">
                            Sort by price: high to low
                          </option>
                        </select>
                      </form>
                      {/* For perpage option*/}
                      <form
                        className="products-per-page product-filter"
                        method="get"
                      >
                        <span className="perpage-label">Show</span>
                        <select
                          value={perPage}
                          onChange={(e) => {
                            setPerPage(e.target.value);
                            setPage(1);
                          }}
                          name="perpage"
                          className="perpage filterSelect"
                          data-select2-id="4"
                          tabIndex="-1"
                          aria-hidden="true"
                        >
                          <option value="10" data-select2-id="6">
                            10
                          </option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                          <option value="25">25</option>
                        </select>
                      </form>
                    </div>
                    <div className="products column-5 mobile-column-2 align-inherit">
                      {items.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="product type-product post-430 status-publish first instock product_cat-meats-seafood product_tag-chicken product_tag-natural product_tag-organic has-post-thumbnail sale shipping-taxable purchasable product-type-simple"
                          >
                            <div className="product-wrapper product-type-1">
                              <div className="thumbnail-wrapper">
                                {/* <div className="product-badges">
                                                                            <span className="badge style-1 onsale">23%</span>
                                                                            <span className="badge style-1 recommend">
                                                                                recommended
                                                                            </span>
                                                                        </div> */}
                                <Link
                                  to={
                                    "/product/" +
                                    item.id +
                                    "/" +
                                    convertToSlug(item.item_name)
                                  }
                                >
                                  <img
                                    src={
                                      item?.image
                                        ? process.env.REACT_APP_BASE_URL +
                                          "/storage/products/" +
                                          item.image
                                        : process.env.REACT_APP_BASE_URL +
                                          "/storage/products/no-image.png"
                                    }
                                    style={{ height: "181px" }}
                                    alt={item?.item_name}
                                  />
                                </Link>
                                <div className="product-buttons">
                                  {/* <a href={430} className="detail-bnt quick-view-button">
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    viewBox="0 0 512 512"
                                                                                >
                                                                                    <path d="M128 32V0H16C7.163 0 0 7.163 0 16v112h32V54.56L180.64 203.2l22.56-22.56L54.56 32H128zM496 0H384v32h73.44L308.8 180.64l22.56 22.56L480 54.56V128h32V16c0-8.837-7.163-16-16-16zM480 457.44L331.36 308.8l-22.56 22.56L457.44 480H384v32h112c8.837 0 16-7.163 16-16V384h-32v73.44zM180.64 308.64L32 457.44V384H0v112c0 8.837 7.163 16 16 16h112v-32H54.56L203.2 331.36l-22.56-22.72z" />
                                                                                </svg>
                                                                            </a> */}
                                  <div
                                    className="tinv-wraper woocommerce tinv-wishlist tinvwl-shortcode-add-to-cart tinvwl-woocommerce_before_shop_loop_item"
                                    onClick={(e) =>
                                      // toggleWishlist(item)
                                      wishlistToggle(item?.id, e)
                                    }
                                    data-tinvwl_product_id={430}
                                  >
                                    <div className="tinv-wishlist-clear" />
                                    <a
                                      role="button"
                                      tabIndex={0}
                                      aria-label="Add to Wishlist"
                                      className={
                                        "tinvwl_add_to_wishlist_button tinvwl-icon-heart tinvwl-position-shortcode" +
                                        (item?.wishlist
                                          ? " tinvwl-product-in-list"
                                          : "")
                                      }
                                      data-tinv-wl-list="[]"
                                      data-tinv-wl-product={430}
                                      data-tinv-wl-productvariation={0}
                                      data-tinv-wl-productvariations="[0]"
                                      data-tinv-wl-producttype="simple"
                                      data-tinv-wl-action="addto"
                                    >
                                      <span className="tinvwl_add_to_wishlist-text">
                                        Add to Wishlist
                                      </span>
                                    </a>
                                    <div className="tinv-wishlist-clear" />{" "}
                                    <div className="tinvwl-tooltip">
                                      Add to Wishlist
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="content-wrapper">
                                <h3 className="product-title">
                                  <Link
                                    to={
                                      "/product/" +
                                      item.id +
                                      "/" +
                                      convertToSlug(item.item_name)
                                    }
                                    title={item?.item_name}
                                  >
                                    {item?.item_name}
                                  </Link>
                                </h3>
                                <div className="product-meta">
                                  {item.stock > 0 ? (
                                    <div className="product-available in-stock">
                                      {" "}
                                      In Stock
                                    </div>
                                  ) : (
                                    <div className="product-available out-of-stock">
                                      {" "}
                                      Out Of Stock
                                    </div>
                                  )}
                                </div>
                                {/* <div className="product-rating">
                                  <div
                                    className="star-rating"
                                    role="img"
                                    aria-label="Rated 4.00 out of 5"
                                  >
                                    <span style={{ width: "80%" }}>
                                      Rated{" "}
                                      <strong className="rating">4.00</strong>{" "}
                                      out of 5
                                    </span>
                                  </div>
                                  <div className="count-rating">
                                    <span className="rating-text">Ratings</span>
                                  </div>
                                </div> */}
                                <div className="">
                                  <div className="">
                                    <span style={{ fontSize: "12px" }}>
                                      Item Code: {item.item_code}
                                    </span>
                                  </div>
                                  <div className="">
                                    <span style={{ fontSize: "12px" }}>
                                      Barcode: {item.custom_barcode}
                                    </span>
                                  </div>
                                </div>
                                <span className="price">
                                  <del aria-hidden="true">
                                    <span className="woocommerce-Price-amount amount">
                                      <bdi>
                                        <span className="woocommerce-Price-currencySymbol">
                                          {/* $ */}
                                        </span>
                                        {/* {item.sales_price} */}
                                      </bdi>
                                    </span>
                                  </del>{" "}
                                  <ins>
                                    {isLogin() && (
                                      <span className="woocommerce-Price-amount amount">
                                        <bdi>
                                          <span className="woocommerce-Price-currencySymbol">
                                            $
                                          </span>
                                          {item.sales_price}
                                        </bdi>
                                      </span>
                                    )}
                                  </ins>
                                </span>
                                <div className="product-fade-block">
                                  <div className="product-button-group cart-with-quantity ">
                                    <div className="quantity ajax-quantity">
                                      <div className="quantity-button minus">
                                        <i className="klbth-icon-minus" />
                                      </div>
                                      <input
                                        type="text"
                                        className="input-text qty text"
                                        name="quantity"
                                        step={1}
                                        min={0}
                                        max={86}
                                        defaultValue={1}
                                        title="Menge"
                                        size={4}
                                        inputMode="numeric"
                                      />
                                      <div className="quantity-button plus">
                                        <i className="klbth-icon-plus" />
                                      </div>
                                    </div>
                                    {/* quantity */}
                                    <Link
                                      href=""
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleAddToCart(item);
                                      }}
                                      data-quantity={1}
                                      className="button-primary xsmall rounded wide button wp-element-button product_type_simple add_to_cart_button ajax_add_to_cart"
                                      data-product_id={430}
                                      data-product_sku="ZU49VOR"
                                      aria-label="Add “All Natural Italian-Style Chicken Meatballs” to your cart"
                                      rel="nofollow"
                                    >
                                      Add to cart
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="product-content-fade border-info"
                              style={{ marginBottom: "-49px" }}
                            />
                            <button
                              className="woosc-btn woosc-btn-430 "
                              data-id={430}
                              data-product_name="All Natural Italian-Style Chicken Meatballs"
                              data-product_image="https://klbtheme.com/bacola/wp-content/uploads/2021/04/product-image-62-150x150.jpg"
                            >
                              Compare
                            </button>
                            <div
                              className="tinv-wraper woocommerce tinv-wishlist tinvwl-after-add-to-cart tinvwl-loop-button-wrapper tinvwl-woocommerce_after_shop_loop_item"
                              data-tinvwl_product_id={430}
                            >
                              <div className="tinv-wishlist-clear" />
                              <a
                                role="button"
                                tabIndex={0}
                                aria-label="Add to Wishlist"
                                className="tinvwl_add_to_wishlist_button tinvwl-icon-heart tinvwl-position-after tinvwl-loop"
                                data-tinv-wl-list="[]"
                                data-tinv-wl-product={430}
                                data-tinv-wl-productvariation={0}
                                data-tinv-wl-productvariations="[0]"
                                data-tinv-wl-producttype="simple"
                                data-tinv-wl-action="addto"
                              >
                                <span className="tinvwl_add_to_wishlist-text">
                                  Add to Wishlist
                                </span>
                              </a>
                              <div className="tinv-wishlist-clear" />{" "}
                              <div className="tinvwl-tooltip">
                                Add to Wishlist
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : null}

                {items.length > 0 ? (
                  <nav className="woocommerce-pagination">
                    <ul className="page-numbers product-cat-pagination">
                      {links.map((link, index) => {
                        return (
                          <li key={index}>
                            <a
                              className={
                                "page-numbers " +
                                (link.active ? "current" : "") +
                                (link.url == null
                                  ? "padination-disabled "
                                  : " ")
                              }
                              href=""
                              onClick={(e) => {
                                e.preventDefault();
                                if (link.url) {
                                  setPage(link.url?.split("page=")[1]);
                                }
                              }}
                            >
                              {link.label
                                .replace(/&laquo; Previous/g, "<< Previous")
                                .replace(/Next &raquo;/g, "Next >>")}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </nav>
                ) : (
                  <div className="product-unavailable">
                    {" "}
                    No Items Available.
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* homepage-content */}
      </div>
      {/* site-content */}
    </main>
  );
};

export default Search;
