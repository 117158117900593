const AboutUs = () => {
  return (
    <main id="main" className="site-primary">
      <div className="site-content">
        <div className="homepage-content">
          <div
            data-elementor-type="wp-page"
            data-elementor-id={3}
            className="elementor elementor-3 privacy-policy"
          >
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-6430715 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id={6430715}
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-95c4fd1"
                  data-id="95c4fd1"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-9cf14cd elementor-widget elementor-widget-heading"
                      data-id="9cf14cd"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <h2 className="elementor-heading-title elementor-size-default">
                          About Us
                        </h2>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-6430715 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id={6430715}
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-95c4fd1"
                  data-id="95c4fd1"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-9cf14cd elementor-widget elementor-widget-heading"
                      data-id="9cf14cd"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        {/* <h5 className="elementor-heading-title elementor-size-default">
                          From the Begining
                        </h5>{" "} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-340630d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="340630d"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2d751b3"
                  data-id="2d751b3"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-5a915c8 elementor-widget elementor-widget-heading"
                      data-id="5a915c8"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <p className="elementor-heading-title elementor-size-default">
                          {/* Founded in 2017, {process.env.REACT_APP_PROJECT} is
                          the absolute premier online vape store offering an
                          industry leading selection of electronic cigarettes &
                          e-liquids Our friendly and knowledgeable customer
                          service team is always ready to provide the best
                          customer service experience to ensure you find what
                          you're looking for. {process.env.REACT_APP_PROJECT}{" "}
                          guarantees our Vape shop products to be 100% genuine
                          and the lowest possible price. We are proud of our
                          offer for three simple reasons: quality, selection,
                          and price. In our e-cigarette vape shop, you can find
                          all your favorite electronic cigarette brands and
                          vapes in one location. */}
                          We are All In One Distribution a New Jersey basedand
                          operated wholesale distributor of tobacco products. We
                          have been in the tobacco industry for since 2023, we
                          serving retailers and wholesalers across the New
                          Jersey.
                        </p>{" "}
                        <p className="mt-4 elementor-heading-title elementor-size-default">
                          {/* We also stock some of the most popular and industry
                          standard brands and products such as the Myle mini,
                          Myle mini 2, Myle pods, Vgod Stig, Puff bars, Puff
                          plus, Puff flow, Pop, Twst, Loy, Smash bar, HQD and
                          many more. Make us your selected e-cig store online
                          and choose from our product offerings that contain
                          everything from the affordable to the exotic! From
                          Bundle deals to Wholesale we are proud to offer it
                          all! Lastly, we carry an extremely diverse and large
                          selection of Wholesale including brands like MYLE,
                          VGOD-STIG, PUFF BAR, HQD and many more! Can't decide?
                          Try one of the monthly featured Bundles! */}
                          Our mission is to provide high-quality tobacco
                          products at competitive prices, with fast and reliable
                          delivery. We offer a wide range of products, from
                          premium cigars and cigarettes to smokeless tobacco and
                          accessories. We source our products from reputable
                          manufacturers and suppliers, ensuring quality and
                          freshness.
                        </p>{" "}
                        <p className="mt-4 elementor-heading-title elementor-size-default">
                          We are more than just a distributor. We are your
                          partner in the tobacco business. We understand the
                          challenges and opportunities that retailers face in
                          this dynamic and evolving market. That's why we offer
                          personalized service, flexible ordering, and
                          innovative merchandising solutions to help you grow
                          your sales and profits.
                        </p>{" "}
                        <p className="mt-4 elementor-heading-title elementor-size-default">
                          We are proud to be a part of the tobacco community,
                          and we are committed to serving our customers with
                          honesty, integrity, and excellence. Whether you are a
                          small convenience store, a large chain, or an online
                          retailer, we have the products and services you need
                          to succeed.
                        </p>{" "}
                        <p className="mt-4 elementor-heading-title elementor-size-default">
                          Contact us today to find out how we can help you with
                          your tobacco needs. We look forward to hearing from
                          you and building a long-lasting relationship.
                        </p>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-22d0774 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="22d0774"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4b82132"
                  data-id="4b82132"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-9c2161e elementor-widget elementor-widget-heading"
                      data-id="9c2161e"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        {/* <h5 className="elementor-heading-title elementor-size-default">
                          Why {process.env.REACT_APP_PROJECT} is your one stop
                          Electronic Cigarette Superstore:
                        </h5>{" "} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-f5067fd elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="f5067fd"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-82e3f04"
                  data-id="82e3f04"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-4ad2047 elementor-widget elementor-widget-heading"
                      data-id="4ad2047"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      {/* <div className="elementor-widget-container">
                        <p className="elementor-heading-title elementor-size-default">
                          1. We specialize in selling Electronic Cigarettes,
                          Vapes and Accessories at the best deal possible.
                        </p>{" "}
                        <p className="elementor-heading-title elementor-size-default">
                          2. We guarantee our products are 100% authentic and
                          fresh from the manufacture. We are authorized retailer
                          from most products manufactures we carry. If you find
                          out products are not authentic or not fresh, we will
                          issue you a full refund.
                        </p>{" "}
                        <p className="elementor-heading-title elementor-size-default">
                          3. We care about our customer, therefore we offer the
                          state of the art customer service. Our friendly and
                          knowledgeable customer service team will answer any
                          question you may have regarding our products and
                          services at any time.
                        </p>{" "}
                        <p className="elementor-heading-title elementor-size-default">
                          4. We send out {process.env.REACT_APP_PROJECT} weekly
                          newsletters to keep your up to date with the most
                          current promotion we have so you can use the extra
                          saving for something else.
                        </p>{" "}
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-22d0774 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="22d0774"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4b82132"
                  data-id="4b82132"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-9c2161e elementor-widget elementor-widget-heading"
                      data-id="9c2161e"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        {/* <h5 className="elementor-heading-title elementor-size-default">
                          Our Mission
                        </h5>{" "} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-f5067fd elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="f5067fd"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-82e3f04"
                  data-id="82e3f04"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-4ad2047 elementor-widget elementor-widget-heading"
                      data-id="4ad2047"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      {/* <div className="elementor-widget-container">
                        <p className="elementor-heading-title elementor-size-default">
                          1. Take care the customers in any way possible.
                        </p>{" "}
                        <p className="elementor-heading-title elementor-size-default">
                          2. Provide the highest quality products possible.
                        </p>{" "}
                        <p className="elementor-heading-title elementor-size-default">
                          3. Bring the best quality products at the lowest
                          possible price. We believe respect, trust, and
                          sincerity are three most important factors that drive
                          us successful. Come and shop with{" "}
                          {process.env.REACT_APP_PROJECT} You will not be
                          disappointed.
                        </p>{" "}
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        {/* homepage-content */}
      </div>
      {/* site-content */}
    </main>
  );
};

export default AboutUs;
