import { Link } from "react-router-dom";

const Discount = () => {
  let ele2 = document?.getElementById("no_right_click2");
  ele2?.addEventListener("contextmenu", (ev) => {
    ev?.preventDefault(); // this will prevent browser default behavior
  });
  return (
    <section
      className="elementor-section elementor-top-section elementor-element elementor-element-3d0b96d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
      data-id="3d0b96d"
      data-element_type="section"
    >
      <div
        className="elementor-container elementor-column-gap-extended"
        id="no_right_click2"
      >
        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-8947bd2"
          data-id="8947bd2"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-0ae80da elementor-widget elementor-widget-bacola-banner-box3"
              data-id="0ae80da"
              data-element_type="widget"
              data-widget_type="bacola-banner-box3.default"
            >
              <div className="elementor-widget-container">
                <div className="site-module module-banner image align-left align-center">
                  <div className="module-body">
                    <div className="banner-wrapper">
                      <div className="banner-content">
                        <div className="content-header">
                          {/* <div className="discount-text color-success">Weekend Discount 40%</div> */}
                        </div>
                        <div className="content-main">
                          {/* <h3 className="entry-title color-text-light">Cookie and Ice Cream</h3> */}
                          {/* <div className="entry-text color-info-dark">Bacola Weekend Discount</div> */}
                        </div>
                        <Link
                          href=""
                          className="button button-primary rounded xsmall"
                        >
                          Shop Now
                        </Link>
                      </div>
                      <div className="banner-thumbnail">
                        <img
                          src="/images/cigar-banner-footer01.png"
                          alt="banner"
                        />
                      </div>
                      <Link href="" className="overlay-link"></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-788ecaf"
          data-id="788ecaf"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-26fb205 elementor-widget elementor-widget-bacola-banner-box3"
              data-id="26fb205"
              data-element_type="widget"
              data-widget_type="bacola-banner-box3.default"
            >
              <div className="elementor-widget-container">
                <div className="site-module module-banner image align-left align-center">
                  <div className="module-body">
                    <div className="banner-wrapper">
                      <div className="banner-content">
                        <div className="content-header">
                          {/* <div className="discount-text color-success">Weekend Discount 30%</div> */}
                        </div>
                        <div className="content-main">
                          {/* <h3 className="entry-title color-text-light">Cookie and Ice Cream</h3> */}
                          {/* <div className="entry-text color-info-dark">Bacola Weekend Discount</div> */}
                        </div>
                        <Link
                          href=""
                          className="button button-danger rounded xsmall"
                        >
                          Shop Now
                        </Link>
                      </div>
                      <div className="banner-thumbnail">
                        <img
                          src="/images/cigar-banner-footer03.png"
                          alt="banner"
                        />
                      </div>
                      <Link href="Link" className="overlay-link"></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Discount;
