import LogoScroll from "../LogoScroll/LogoScroll";
import FooterBottom from "./FooterBottom";
import FooterContacts from "./FooterContacts";
import FooterIconBoxes from "./FooterIconBoxes";
import FooterNewForBottom from "./FooterNewForBottom";
import FooterSubscribe from "./FooterSubscribe";
import FooterWidget from "./FooterWidgets";

const Footer = () => {
  let elemnt = document?.getElementById(`disable_right_click`);
  elemnt?.addEventListener("contextmenu", (ev) => {
    ev?.preventDefault(); // this will prevent browser default behavior
  });
  return (
    <footer className="site-footer" id="disable_right_click">
      <FooterSubscribe />

      <FooterIconBoxes />

      {/* <FooterWidget /> */}
      <LogoScroll />
      <FooterContacts />
      {/* <FooterNewForBottom /> */}
      <FooterBottom />
    </footer>
  );
};

export default Footer;
