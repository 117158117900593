import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { retriveCategories } from "../services/CategoryService";
import _ from "lodash";

const Category = () => {
  const [categories, setcategories] = useState([]);
  const [toggleCategory, settoggleCategory] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (toggleCategory) {
      document.getElementById("all-categories").classList.toggle("show");
    } else {
      document.getElementById("all-categories").classList.remove("show");
    }
    return () => {
      // settoggleCategory(false);
      // document.getElementById("all-categories").classList.remove("show");
    };
  }, [toggleCategory]);

  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        // document.getElementById("all-categories").classList.toggle("show");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);

  const getCategories = async () => {
    await retriveCategories()
      .then((response) => {
        if (response.data.data) {
          setcategories(response.data.data);
        }
      })
      .catch((error) => {
        console.log("Error", error.response);
      });
  };

  const hideCategoryDropdown = (slug, e, catSlug) => {
    e?.preventDefault();
    if (!_.isEmpty(slug) && _.isEmpty(catSlug)) {
      navigate(`/product-category/${slug}`);
    } else if (!_.isEmpty(slug) && !_.isEmpty(catSlug)) {
      navigate(`/product-category/${slug}/${catSlug}`);
    }
    settoggleCategory(false);
    document.getElementById("all-categories").classList.remove("show");
  };

  return (
    <div className="all-categories locked" ref={ref}>
      <a
        //   href=""
        onClick={(e) => {
          e.preventDefault();
          settoggleCategory(!toggleCategory);
          // document.getElementById("all-categories").classList.toggle("show");
        }}
        data-toggle="collapse"
        data-target="#all-categories"
      >
        <i className="klbth-icon-menu-thin"></i>
        <span className="text">ALL CATEGORIES</span>
        <div className="description">TOTAL 63 PRODUCTS</div>
      </a>

      <div className="dropdown-categories collapse" id="all-categories">
        <ul id="menu-sidebar-menu-1" className="menu-list">
          {categories.map((category, index) => {
            if (category.parent_id == null) {
              return (
                <li
                  key={index}
                  className={
                    "category-parent menu-item menu-item-type-taxonomy menu-item-object-product_cat" +
                    (categories.filter((el) => el.parent_id == category.id)
                      .length > 0
                      ? " parent"
                      : "")
                  }
                >
                  <a
                    onClick={(e) => hideCategoryDropdown(category.slug, e)}

                    // to={"/product-category/" + category.slug}
                  >
                    {/* <i className="klbth-icon-biscuit"></i>  */}
                    {category.category_name}
                  </a>
                  {categories.filter((el) => el.parent_id == category.id)
                    .length > 0 ? (
                    <ul className="sub-menu">
                      {categories.map((sub_cat, index) => {
                        if (sub_cat.parent_id == category.id) {
                          return (
                            <li
                              key={index}
                              className={
                                "category-parent  menu-item menu-item-type-taxonomy menu-item-object-product_cat"
                              }
                            >
                              <a
                                onClick={(e) =>
                                  hideCategoryDropdown(
                                    category.slug,
                                    e,
                                    sub_cat.slug
                                  )
                                }
                                // to={
                                //   "/product-category/" +
                                //   category.slug +
                                //   "/" +
                                //   sub_cat.slug
                                // }
                              >
                                {sub_cat.category_name}
                              </a>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  ) : null}
                </li>
              );
            }
          })}

          {/* <li className="link-parent  menu-item menu-item-type-custom menu-item-object-custom"><a
                        href="#/shop/?on_sale=onsale"><i className="bottom"></i> Value of the
                        Day</a></li>
                     <li className="link-parent  menu-item menu-item-type-custom menu-item-object-custom"><a
                        href="#/shop/?filter_cat=58,35,31"><i className="bottom"></i> Top 100
                        Offers</a></li>
                     <li className="link-parent  menu-item menu-item-type-custom menu-item-object-custom"><a
                        href="#/shop/"><i className="bottom"></i> New Arrivals</a></li> */}
        </ul>
      </div>
    </div>
  );
};

export default Category;
