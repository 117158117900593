import { useContext, useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  createOrder,
  retriveCartAndCustomer,
} from "../../services/OrderService";
import { getAllStates } from "../../services/StateService";
import { AppContext } from "../../utils/AppProvider";

const Checkout = () => {
  const [order, setOrder] = useState({
    billing_address: {},
    shipping_address: {},
    items: [],
    subtotal: 0,
    tax: 0,
    total: 0,
    payment_method: "Cash On Dilevery",
    note: "",
    hasShippingAddress: false,
  });
  const [acceptTAC, setAcceptTAC] = useState(false);
  const [states, setStates] = useState([]);
  const navigate = useNavigate();
  const { setCartQty } = useContext(AppContext);

  useEffect(() => {
    getDetails();
    getState();
  }, []);

  const getState = async () => {
    await getAllStates()
      .then((response) => {
        setStates(response.data);
      })
      .catch((error) => {});
  };

  const getDetails = async () => {
    await retriveCartAndCustomer()
      .then((response) => {
        if (response?.data?.data) {
          let data = response.data.data;

          if (data.status !== "approved") {
            toast("Only approved customer can order");
            navigate("/cart");
          }

          let bill_add = {
            name: data.name,
            company_name: data.company_name,
            address: data.address,
            city: data.city,
            state_id: data.state_id,
            pincode: data.pincode,
            phone: data.phone,
            email: data.email,
          };

          let shipp_add = {};

          if (data.hasOwnProperty("addresses") && data.addresses.length > 0) {
            let temp = data.addresses[0];
            shipp_add = {
              name: data.name,
              company_name: data.company_name,
              address: temp.address,
              city: temp.city,
              state_id: temp.state_id,
              pincode: temp.pincode,
              // state_name: temp.state?.name,
              // shipp_add_id: temp.id,
            };
          }
          let items = [];
          let st = 0;
          let tax = 0;
          items = data.cart_items.map((i, index) => {
            st += i.quantity * i.item.sales_price;
            tax += i.quantity * parseFloat(i.tax?.tax_per_unit ?? 0);
            return {
              item_name: i.item.item_name,
              item_id: i.item_id,
              quantity: i.quantity,
              item_price: i.item.sales_price,
              subtotal: i.quantity * i.item.sales_price,
              tax: i.tax,
            };
          });

          if (items.length <= 0) {
            navigate("/cart");
          }

          // st = st.toFixed(2)
          // tax = tax.toFixed(2)
          setOrder({
            ...order,
            ["billing_address"]: bill_add,
            ["shipping_address"]: shipp_add,
            ["items"]: items,
            ["subtotal"]: st.toFixed(2),
            ["tax"]: tax.toFixed(2),
            ["total"]: (st + tax).toFixed(2),
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const handleBillingAddChange = (e) => {
    let { name, value } = e.target;
    let bill_add = { ...order.billing_address };
    bill_add[name] = value;

    setOrder({ ...order, ["billing_address"]: bill_add });
  };

  const handleShippingAddChange = (e) => {
    let { name, value } = e.target;
    let shipp_add = { ...order.shipping_address };
    shipp_add[name] = value;

    setOrder({ ...order, ["shipping_address"]: shipp_add });
  };

  const handleOrderSubmit = async (e) => {
    if (validateInput()) {
      await createOrder(order)
        .then((response) => {
          toast("Order Created Successfully.", { type: "success" });
          setCartQty(0);
          navigate("/my-account/orders");
        })
        .catch((error) => {
          if (error.response.status == 422)
            toast(error.response.data.error, { type: "error" });
          else if (error.response.status == 500)
            toast(error.response.data.message, { type: "error" });
          else toast("Something went wrong", { type: "error" });
        });
    }
  };

  const validateInput = () => {
    // Validate Billing Address
    if (order.billing_address.name == "") {
      toast("Please enter name in billing address.", {
        type: "error",
      });
      return false;
    }
    if (order.billing_address.address == "") {
      toast("Please enter address in billing address.", {
        type: "error",
      });
      return false;
    }
    if (order.billing_address.city == "") {
      toast("Please enter city in billing address.", {
        type: "error",
      });
      return false;
    }
    if (order.billing_address.state_id == "") {
      toast("Please select state in billing address.", {
        type: "error",
      });
      return false;
    }
    if (order.billing_address.pincode == "") {
      toast("Please enter pincode in billing address.", {
        type: "error",
      });
      return false;
    }
    if (order.billing_address.phone == "") {
      toast("Please enter phone.", {
        type: "error",
      });
      return false;
    }
    if (order.billing_address.email == "") {
      toast("Please enter email address.", {
        type: "error",
      });
      return false;
    }

    // Validate Shipping Address If Enabled
    if (order.hasShippingAddress) {
      if (order.shipping_address.name == "") {
        toast("Please enter name in shipping address.", {
          type: "error",
        });
        return false;
      }
      if (order.shipping_address.address == "") {
        toast("Please enter address in shipping address.", {
          type: "error",
        });
        return false;
      }
      if (order.shipping_address.city == "") {
        toast("Please enter city in shipping address.", {
          type: "error",
        });
        return false;
      }
      if (order.shipping_address.state_id == "") {
        toast("Please select state in shipping address.", {
          type: "error",
        });
        return false;
      }
      if (order.shipping_address.pincode == "") {
        toast("Please enter pincode in shipping address.", {
          type: "error",
        });
        return false;
      }
    }

    if (!acceptTAC) {
      toast("Please accept Term and Condition.", {
        type: "error",
      });
      return false;
    }

    return true;
  };

  return (
    <main id="main" className="site-primary">
      <div className="site-content">
        <div className="homepage-content">
          <div className="shop-content">
            <div className="container">
              <nav className="woocommerce-breadcrumb">
                <ul>
                  <li>
                    <Link href="/">Home</Link>
                  </li>
                  <li>Checkout</li>
                </ul>
              </nav>
              <div className="woocommerce">
                <div className="woocommerce-notices-wrapper" />
                {/*  <div className="woocommerce-form-coupon-toggle">
                                    <div className="woocommerce-info">
                                        Have a coupon?{" "}
                                        <a href="#" className="showcoupon">
                                            Click here to enter your code
                                        </a>{" "}
                                    </div>
                                </div> */}
                {/* <form
                                    className="checkout_coupon woocommerce-form-coupon"
                                    method="post"
                                    style={{ display: "none" }}
                                >
                                    <p>If you have a coupon code, please apply it below.</p>
                                    <p className="form-row form-row-first">
                                        <label htmlFor="coupon_code" className="screen-reader-text">
                                            Coupon:
                                        </label>
                                        <input
                                            type="text"
                                            name="coupon_code"
                                            className="input-text"
                                            placeholder="Coupon code"
                                            id="coupon_code"
                                            defaultValue=""
                                        />
                                    </p>
                                    <p className="form-row form-row-last">
                                        <button
                                            type="submit"
                                            className="button wp-element-button"
                                            name="apply_coupon"
                                            value="Apply coupon"
                                        >
                                            Apply coupon
                                        </button>
                                    </p>
                                    <div className="clear" />
                                </form> */}
                <div className="woocommerce-notices-wrapper" />
                <form
                  name="checkout"
                  className="checkout woocommerce-checkout"
                  encType="multipart/form-data"
                  noValidate="novalidate"
                >
                  <div className="cart-form-wrapper">
                    <div className="row content-wrapper sidebar-right">
                      <div className="col-12 col-md-12 col-lg-12 mt-2 content-primary">
                        <div className="cart-wrapper">
                          {/* <div className="klb-free-progress-bar">
                                                        <div className="free-shipping-notice">
                                                            Add{" "}
                                                            <span className="woocommerce-Price-amount amount">
                                                                <span className="woocommerce-Price-currencySymbol">
                                                                    $
                                                                </span>
                                                                23.86
                                                            </span>{" "}
                                                            to cart and get free shipping!{" "}
                                                        </div>
                                                        <div className="klb-progress-bar">
                                                            <span className="progress" style={{ width: "52%" }} />
                                                        </div>
                                                    </div> */}
                          <div className="col2-set" id="customer_details">
                            <div className="col-1">
                              <div className="woocommerce-billing-fields">
                                <h3>Billing details</h3>
                                <div className="woocommerce-billing-fields__field-wrapper">
                                  <p
                                    className="form-row form-row-wide validate-required"
                                    id="billing_name_field"
                                  >
                                    <label
                                      htmlFor="billing_first_name"
                                      className=""
                                    >
                                      Full name&nbsp;
                                      <abbr
                                        className="required"
                                        title="required"
                                      >
                                        *
                                      </abbr>
                                    </label>
                                    <span className="woocommerce-input-wrapper">
                                      <input
                                        type="text"
                                        className="input-text "
                                        name="name"
                                        disabled={true}
                                        id="billing_first_name"
                                        placeholder=""
                                        value={order.billing_address?.name}
                                        onChange={(e) =>
                                          handleBillingAddChange(e)
                                        }
                                        autoComplete="given-name"
                                      />
                                    </span>
                                  </p>
                                  <p
                                    className="form-row form-row-wide"
                                    id="billing_company_field"
                                    data-priority={30}
                                  >
                                    <label
                                      htmlFor="billing_company"
                                      className=""
                                    >
                                      Company name&nbsp;
                                      <span className="optional">
                                        (optional)
                                      </span>
                                    </label>
                                    <span className="woocommerce-input-wrapper">
                                      <input
                                        type="text"
                                        className="input-text "
                                        name="company_name"
                                        disabled={true}
                                        id="billing_company"
                                        placeholder=""
                                        value={
                                          order.billing_address?.company_name
                                        }
                                        onChange={(e) =>
                                          handleBillingAddChange(e)
                                        }
                                        autoComplete="organization"
                                      />
                                    </span>
                                  </p>

                                  <p
                                    className="form-row address-field validate-required form-row-wide"
                                    id="billing_address_1_field"
                                    data-priority={50}
                                  >
                                    <label
                                      htmlFor="billing_address_1"
                                      className=""
                                    >
                                      Address&nbsp;
                                      <abbr
                                        className="required"
                                        title="required"
                                      >
                                        *
                                      </abbr>
                                    </label>
                                    <span className="woocommerce-input-wrapper">
                                      <textarea
                                        rows={2}
                                        className="input-text "
                                        name="address"
                                        disabled={true}
                                        id="billing_address_1"
                                        placeholder="House number and street name"
                                        value={order.billing_address?.address}
                                        onChange={(e) =>
                                          handleBillingAddChange(e)
                                        }
                                        autoComplete="address-line1"
                                        data-placeholder="House number and street name"
                                      >
                                        {" "}
                                      </textarea>
                                    </span>
                                  </p>

                                  <p
                                    className="form-row address-field validate-required form-row-wide"
                                    id="billing_city_field"
                                    data-priority={70}
                                    data-o_class="form-row form-row-wide address-field validate-required"
                                  >
                                    <label htmlFor="billing_city" className="">
                                      Town / City&nbsp;
                                      <abbr
                                        className="required"
                                        title="required"
                                      >
                                        *
                                      </abbr>
                                    </label>
                                    <span className="woocommerce-input-wrapper">
                                      <input
                                        type="text"
                                        disabled={true}
                                        className="input-text "
                                        name="city"
                                        id="billing_city"
                                        placeholder=""
                                        value={order.billing_address?.city}
                                        onChange={(e) =>
                                          handleBillingAddChange(e)
                                        }
                                        autoComplete="address-level2"
                                      />
                                    </span>
                                  </p>
                                  <p
                                    className="form-row address-field validate-required validate-state form-row-wide"
                                    id="billing_state_field"
                                    data-priority={80}
                                    data-o_class="form-row form-row-wide address-field validate-required validate-state"
                                  >
                                    <label htmlFor="billing_state" className="">
                                      State&nbsp;
                                      <abbr
                                        className="required"
                                        title="required"
                                      >
                                        *
                                      </abbr>
                                    </label>
                                    <span className="woocommerce-input-wrapper">
                                      <select
                                        name="state_id"
                                        id="billing_state"
                                        disabled={true}
                                        className="state_select"
                                        autoComplete="address-level1"
                                        data-placeholder="Select an option…"
                                        data-input-classes=""
                                        data-label="State"
                                        tabIndex={-1}
                                        value={order.billing_address?.state_id}
                                        onChange={(e) =>
                                          handleBillingAddChange(e)
                                        }
                                        aria-hidden="true"
                                      >
                                        <option value="">Select State</option>
                                        {states.map((state, index) => {
                                          return (
                                            <option value={state.id}>
                                              {" "}
                                              {state.name}{" "}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      {/* <span
                                                                                className="select2 select2-container select2-container--default select2-container--below"
                                                                                dir="ltr"
                                                                                style={{ width: "100%" }}
                                                                            >
                                                                                <span className="selection">
                                                                                    <span
                                                                                        className="select2-selection select2-selection--single"
                                                                                        aria-haspopup="true"
                                                                                        aria-expanded="false"
                                                                                        tabIndex={0}
                                                                                        aria-label="State"
                                                                                        role="combobox"
                                                                                    >
                                                                                        <span
                                                                                            className="select2-selection__rendered"
                                                                                            id="select2-billing_state-container"
                                                                                            role="textbox"
                                                                                            aria-readonly="true"
                                                                                            title="Alabama"
                                                                                        >
                                                                                            Alabama
                                                                                        </span>
                                                                                        <span
                                                                                            className="select2-selection__arrow"
                                                                                            role="presentation"
                                                                                        >
                                                                                            <b role="presentation" />
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                                <span
                                                                                    className="dropdown-wrapper"
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </span> */}
                                    </span>
                                  </p>
                                  <p
                                    className="form-row address-field validate-required validate-postcode form-row-wide"
                                    id="billing_postcode_field"
                                    data-priority={90}
                                    data-o_class="form-row form-row-wide address-field validate-required validate-postcode"
                                  >
                                    <label
                                      htmlFor="billing_postcode"
                                      className=""
                                    >
                                      ZIP Code&nbsp;
                                      <abbr
                                        className="required"
                                        title="required"
                                      >
                                        *
                                      </abbr>
                                    </label>
                                    <span className="woocommerce-input-wrapper">
                                      <input
                                        type="text"
                                        className="input-text "
                                        name="pincode"
                                        disabled={true}
                                        id="billing_postcode"
                                        placeholder=""
                                        value={order.billing_address?.pincode}
                                        onChange={(e) =>
                                          handleBillingAddChange(e)
                                        }
                                        autoComplete="postal-code"
                                      />
                                    </span>
                                  </p>
                                  <p
                                    className="form-row form-row-wide validate-required validate-phone"
                                    id="billing_phone_field"
                                    data-priority={100}
                                  >
                                    <label htmlFor="billing_phone" className="">
                                      Phone&nbsp;
                                      <abbr
                                        className="required"
                                        title="required"
                                      >
                                        *
                                      </abbr>
                                    </label>
                                    <span className="woocommerce-input-wrapper">
                                      <input
                                        type="tel"
                                        // disabled={true}
                                        className="input-text "
                                        name="phone"
                                        id="billing_phone"
                                        placeholder=""
                                        value={order.billing_address?.phone}
                                        onChange={(e) =>
                                          handleBillingAddChange(e)
                                        }
                                        autoComplete="tel"
                                      />
                                    </span>
                                  </p>
                                  <p
                                    className="form-row form-row-wide validate-required validate-email"
                                    id="billing_email_field"
                                    data-priority={110}
                                  >
                                    <label htmlFor="billing_email" className="">
                                      Email address&nbsp;
                                      <abbr
                                        className="required"
                                        title="required"
                                      >
                                        *
                                      </abbr>
                                    </label>
                                    <span className="woocommerce-input-wrapper">
                                      <input
                                        type="email"
                                        className="input-text "
                                        name="email"
                                        id="billing_email"
                                        disabled={true}
                                        placeholder=""
                                        value={order.billing_address?.email}
                                        onChange={(e) =>
                                          handleBillingAddChange(e)
                                        }
                                        autoComplete="email username"
                                      />
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="woocommerce-shipping-fields">
                                <h3 id="ship-to-different-address">
                                  <label className="woocommerce-form__label woocommerce-form__label-for-checkbox checkbox">
                                    <input
                                      id="ship-to-different-address-checkbox"
                                      className="woocommerce-form__input woocommerce-form__input-checkbox input-checkbox"
                                      type="checkbox"
                                      name="ship_to_different_address"
                                      checked={order.hasShippingAddress}
                                      disabled={true}
                                      onChange={(e) => {
                                        let { checked } = e.target;
                                        setOrder({
                                          ...order,
                                          ["hasShippingAddress"]: checked,
                                        });
                                      }}
                                    />{" "}
                                    <span>Ship to a different address?</span>
                                  </label>
                                </h3>
                                <div
                                  className="shipping_address"
                                  style={{
                                    display: order.hasShippingAddress
                                      ? "block"
                                      : "none",
                                  }}
                                >
                                  <div className="woocommerce-shipping-fields__field-wrapper">
                                    <p
                                      className="form-row form-row-first validate-required"
                                      id="shipping_first_name_field"
                                      data-priority={10}
                                    >
                                      <label
                                        htmlFor="shipping_first_name"
                                        className=""
                                      >
                                        Full Name&nbsp;
                                        <abbr
                                          className="required"
                                          title="required"
                                        >
                                          *
                                        </abbr>
                                      </label>
                                      <span className="woocommerce-input-wrapper">
                                        <input
                                          type="text"
                                          className="input-text "
                                          name="name"
                                          id="shipping_first_name"
                                          disabled={true}
                                          placeholder=""
                                          value={order.shipping_address?.name}
                                          onChange={(e) =>
                                            handleShippingAddChange(e)
                                          }
                                          autoComplete="given-name"
                                        />
                                      </span>
                                    </p>

                                    <p
                                      className="form-row form-row-wide"
                                      id="shipping_company_field"
                                      data-priority={30}
                                    >
                                      <label
                                        htmlFor="shipping_company"
                                        className=""
                                      >
                                        Company name&nbsp;
                                        <span className="optional">
                                          (optional)
                                        </span>
                                      </label>
                                      <span className="woocommerce-input-wrapper">
                                        <input
                                          type="text"
                                          className="input-text "
                                          name="company_name"
                                          disabled={true}
                                          id="shipping_company"
                                          placeholder=""
                                          value={
                                            order.shipping_address?.company_name
                                          }
                                          onChange={(e) =>
                                            handleShippingAddChange(e)
                                          }
                                          autoComplete="organization"
                                        />
                                      </span>
                                    </p>

                                    <p
                                      className="form-row address-field validate-required form-row-wide"
                                      id="shipping_address_1_field"
                                      data-priority={50}
                                    >
                                      <label
                                        htmlFor="shipping_address_1"
                                        className=""
                                      >
                                        Street address&nbsp;
                                        <abbr
                                          className="required"
                                          title="required"
                                        >
                                          *
                                        </abbr>
                                      </label>
                                      <span className="woocommerce-input-wrapper">
                                        <input
                                          type="text"
                                          className="input-text "
                                          disabled={true}
                                          name="address"
                                          id="shipping_address_1"
                                          placeholder="House number and street name"
                                          value={
                                            order.shipping_address?.address
                                          }
                                          onChange={(e) =>
                                            handleShippingAddChange(e)
                                          }
                                          autoComplete="address-line1"
                                          data-placeholder="House number and street name"
                                        />
                                      </span>
                                    </p>

                                    <p
                                      className="form-row address-field validate-required form-row-wide"
                                      id="shipping_city_field"
                                      data-priority={70}
                                      data-o_class="form-row form-row-wide address-field validate-required"
                                    >
                                      <label
                                        htmlFor="shipping_city"
                                        className=""
                                      >
                                        Town / City&nbsp;
                                        <abbr
                                          className="required"
                                          title="required"
                                        >
                                          *
                                        </abbr>
                                      </label>
                                      <span className="woocommerce-input-wrapper">
                                        <input
                                          type="text"
                                          disabled={true}
                                          className="input-text "
                                          name="city"
                                          id="shipping_city"
                                          placeholder=""
                                          value={order.shipping_address?.city}
                                          onChange={(e) =>
                                            handleShippingAddChange(e)
                                          }
                                          autoComplete="address-level2"
                                        />
                                      </span>
                                    </p>
                                    <p
                                      className="form-row address-field validate-required validate-state form-row-wide"
                                      id="shipping_state_field"
                                      data-priority={80}
                                      data-o_class="form-row form-row-wide address-field validate-required validate-state"
                                    >
                                      <label
                                        htmlFor="shipping_state"
                                        className=""
                                      >
                                        State&nbsp;
                                        <abbr
                                          className="required"
                                          title="required"
                                        >
                                          *
                                        </abbr>
                                      </label>
                                      <span className="woocommerce-input-wrapper">
                                        <select
                                          name="state_id"
                                          id="shipping_state"
                                          disabled={true}
                                          className="state_select"
                                          autoComplete="address-level1"
                                          data-placeholder="Select an option…"
                                          data-input-classes=""
                                          data-label="State"
                                          tabIndex={-1}
                                          value={
                                            order.shipping_address?.state_id
                                          }
                                          onChange={(e) =>
                                            handleShippingAddChange(e)
                                          }
                                          aria-hidden="true"
                                        >
                                          <option value="">
                                            {" "}
                                            Select State
                                          </option>
                                          {states.map((state, index) => {
                                            return (
                                              <option value={state.id}>
                                                {" "}
                                                {state.name}{" "}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </span>
                                    </p>
                                    <p
                                      className="form-row address-field validate-required validate-postcode form-row-wide"
                                      id="shipping_postcode_field"
                                      data-priority={90}
                                      data-o_class="form-row form-row-wide address-field validate-required validate-postcode"
                                    >
                                      <label
                                        htmlFor="shipping_postcode"
                                        className=""
                                      >
                                        ZIP Code&nbsp;
                                        <abbr
                                          className="required"
                                          title="required"
                                        >
                                          *
                                        </abbr>
                                      </label>
                                      <span className="woocommerce-input-wrapper">
                                        <input
                                          type="text"
                                          disabled={true}
                                          className="input-text "
                                          name="pincode"
                                          id="shipping_postcode"
                                          placeholder=""
                                          value={
                                            order.shipping_address?.pincode
                                          }
                                          onChange={(e) =>
                                            handleShippingAddChange(e)
                                          }
                                          autoComplete="postal-code"
                                        />
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="woocommerce-additional-fields">
                                <div className="woocommerce-additional-fields__field-wrapper">
                                  <p
                                    className="form-row notes"
                                    id="order_comments_field"
                                    data-priority=""
                                  >
                                    <label
                                      htmlFor="order_comments"
                                      className=""
                                    >
                                      Order notes&nbsp;
                                      <span className="optional">
                                        (optional)
                                      </span>
                                    </label>
                                    <span className="woocommerce-input-wrapper">
                                      <textarea
                                        name="order_comments"
                                        className="input-text "
                                        id="order_comments"
                                        placeholder="Notes about your order, e.g. special notes for delivery."
                                        rows={2}
                                        disabled={true}
                                        cols={5}
                                        value={order.note}
                                        onChange={(e) => {
                                          let { value } = e.target;
                                          setOrder({
                                            ...order,
                                            ["note"]: value,
                                          });
                                        }}
                                      />
                                    </span>
                                  </p>{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="order-review-wrapper">
                            <h3 id="order_review_heading">Your order</h3>
                            <div
                              id="order_review"
                              className="woocommerce-checkout-review-order"
                            >
                              <table
                                className="shop_table woocommerce-checkout-review-order-table"
                                style={{ position: "static", zoom: 1 }}
                              >
                                <thead>
                                  <tr>
                                    <th className="product-name">Product</th>
                                    <th className="product-total">Subtotal</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {order.items.map((i, index) => {
                                    return (
                                      <tr className="cart_item">
                                        <td className="product-name">
                                          {i.item_name} &nbsp;{" "}
                                          <strong className="product-quantity">
                                            ×&nbsp; {i.quantity}
                                          </strong>{" "}
                                        </td>
                                        <td className="product-total">
                                          <span className="woocommerce-Price-amount amount">
                                            <bdi>
                                              <span className="woocommerce-Price-currencySymbol">
                                                $
                                              </span>
                                              {i.subtotal.toFixed(2)}
                                            </bdi>
                                          </span>{" "}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                                <tfoot>
                                  <tr className="cart-subtotal">
                                    <th>Subtotal</th>
                                    <td>
                                      <span className="woocommerce-Price-amount amount">
                                        <bdi>
                                          <span className="woocommerce-Price-currencySymbol">
                                            $
                                          </span>
                                          {order.subtotal}
                                        </bdi>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr className="cart-subtotal">
                                    <th>Tax</th>
                                    <td>
                                      <span className="woocommerce-Price-amount amount">
                                        <bdi>
                                          <span className="woocommerce-Price-currencySymbol">
                                            $
                                          </span>
                                          {order.tax}
                                        </bdi>
                                      </span>
                                    </td>
                                  </tr>

                                  {/*  <tr className="woocommerce-shipping-totals shipping">
                                                                        <th>Shipping</th>
                                                                        <td data-title="Shipping">
                                                                            <ul
                                                                                id="shipping_method"
                                                                                className="woocommerce-shipping-methods"
                                                                            >
                                                                                <li>
                                                                                    <input
                                                                                        type="radio"
                                                                                        name="shipping_method[0]"
                                                                                        data-index={0}
                                                                                        id="shipping_method_0_flat_rate1"
                                                                                        defaultValue="flat_rate:1"
                                                                                        className="shipping_method"
                                                                                        defaultChecked="checked"
                                                                                    />
                                                                                    <label htmlFor="shipping_method_0_flat_rate1">
                                                                                        Flat rate:{" "}
                                                                                        <span className="woocommerce-Price-amount amount">
                                                                                            <bdi>
                                                                                                <span className="woocommerce-Price-currencySymbol">
                                                                                                    $
                                                                                                </span>
                                                                                                5.00
                                                                                            </bdi>
                                                                                        </span>
                                                                                    </label>{" "}
                                                                                </li>
                                                                                <li>
                                                                                    <input
                                                                                        type="radio"
                                                                                        name="shipping_method[0]"
                                                                                        data-index={0}
                                                                                        id="shipping_method_0_local_pickup3"
                                                                                        defaultValue="local_pickup:3"
                                                                                        className="shipping_method"
                                                                                    />
                                                                                    <label htmlFor="shipping_method_0_local_pickup3">
                                                                                        Local pickup
                                                                                    </label>{" "}
                                                                                </li>
                                                                            </ul>
                                                                        </td>
                                                                    </tr> */}
                                  <tr className="order-total">
                                    <th>Total</th>
                                    <td>
                                      <strong>
                                        <span className="woocommerce-Price-amount amount">
                                          <bdi>
                                            <span className="woocommerce-Price-currencySymbol">
                                              $
                                            </span>
                                            {order.total}
                                          </bdi>
                                        </span>
                                      </strong>{" "}
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                              <div
                                id="payment"
                                className="woocommerce-checkout-payment"
                                style={{ position: "static", zoom: 1 }}
                              >
                                <ul className="wc_payment_methods payment_methods methods">
                                  <li className="wc_payment_method payment_method_bacs">
                                    <input
                                      id="payment_method_bacs"
                                      type="radio"
                                      className="input-radio"
                                      name="payment_method"
                                      defaultValue="bacs"
                                      disabled
                                      // defaultChecked="checked"
                                      data-order_button_text=""
                                    />
                                    <label htmlFor="payment_method_bacs">
                                      Direct bank transfer{" "}
                                    </label>
                                    <div
                                      className="payment_box payment_method_bacs"
                                      style={{ display: "none" }}
                                    >
                                      <p>
                                        Make your payment directly into our bank
                                        account. Please use your Order ID as the
                                        payment reference. Your order will not
                                        be shipped until the funds have cleared
                                        in our account.
                                      </p>
                                    </div>
                                  </li>
                                  <li className="wc_payment_method payment_method_cheque">
                                    <input
                                      id="payment_method_cheque"
                                      type="radio"
                                      className="input-radio"
                                      name="payment_method"
                                      defaultValue="cheque"
                                      data-order_button_text=""
                                      disabled
                                    />
                                    <label htmlFor="payment_method_cheque">
                                      Check payments{" "}
                                    </label>
                                    <div
                                      className="payment_box payment_method_cheque"
                                      style={{ display: "none" }}
                                    >
                                      <p>
                                        Please send a check to Store Name, Store
                                        Street, Store Town, Store State /
                                        County, Store Postcode.
                                      </p>
                                    </div>
                                  </li>
                                  <li className="wc_payment_method payment_method_cod">
                                    <input
                                      id="payment_method_cod"
                                      type="radio"
                                      className="input-radio"
                                      name="payment_method"
                                      defaultValue="cod"
                                      checked
                                      data-order_button_text=""
                                    />
                                    <label htmlFor="payment_method_cod">
                                      Cash on delivery{" "}
                                    </label>
                                    <div
                                      className="payment_box payment_method_cod"
                                      style={{ display: "block" }}
                                    >
                                      <p>Pay with cash upon delivery.</p>
                                    </div>
                                  </li>
                                </ul>
                                <div className="form-row place-order">
                                  <noscript>
                                    Since your browser does not support
                                    JavaScript, or it is disabled, please ensure
                                    you click the &lt;em&gt;Update
                                    Totals&lt;/em&gt; button before placing your
                                    order. You may be charged more than the
                                    amount stated above if you fail to do so.
                                    &lt;br/&gt;&lt;button type="submit"
                                    class="button alt wp-element-button"
                                    name="woocommerce_checkout_update_totals"
                                    value="Update totals"&gt;Update
                                    totals&lt;/button&gt;
                                  </noscript>
                                  <div className="woocommerce-terms-and-conditions-wrapper">
                                    <div className="woocommerce-privacy-policy-text">
                                      <p>
                                        Your personal data will be used to
                                        process your order, support your
                                        experience throughout this website, and
                                        for other purposes described in our{" "}
                                        <a
                                          href="#https://klbtheme.com/bacola/privacy-policy/"
                                          className="woocommerce-privacy-policy-link"
                                          target="_blank"
                                        >
                                          privacy policy
                                        </a>
                                        .
                                      </p>
                                    </div>
                                    <div
                                      className="woocommerce-terms-and-conditions"
                                      style={{
                                        display: "none",
                                        maxHeight: 200,
                                        overflow: "auto",
                                      }}
                                    >
                                      <h3>Our Terms &amp; Conditions</h3>
                                      <p>
                                        It is a long established fact that a
                                        reader will be distracted by the
                                        readable content of a page when looking
                                        at its layout. The point of using Lorem
                                        Ipsum is that it has a more-or-less
                                        normal distribution of letters, as
                                        opposed to using ‘Content here, content
                                        here’, making it look like readable
                                        English. Many desktop publishing
                                        packages and web page editors now use
                                        Lorem Ipsum as their default model text,
                                        and a search for ‘lorem ipsum’ will
                                        uncover many web sites still in their
                                        infancy. Various versions have evolved
                                        over the years, sometimes by accident,
                                        sometimes on purpose injected humour and
                                        the like
                                        <br />
                                        There are many variations of passages of
                                        Lorem Ipsum available, but the majority
                                        have suffered alteration in some form,
                                        by injected humour, or randomised words
                                        which don’t look even slightly
                                        believable. If you are going to use a
                                        passage of Lorem Ipsum, you need to be
                                        sure there isn’t anything embarrassing
                                        hidden in the middle of text.
                                      </p>
                                      <h3>
                                        Where does it come from?
                                        <br />
                                      </h3>
                                      <p>
                                        It is a long established fact that a
                                        reader will be distracted by the
                                        readable content of a page when looking
                                        at its layout. The point of using Lorem
                                        Ipsum is that it has a more-or-less
                                        normal distribution of letters, as
                                        opposed to using ‘Content here, content
                                        here’, making it look like readable
                                        English. Many desktop publishing
                                        packages and web page editors now use
                                        Lorem Ipsum as their default model text,
                                        and a search for ‘lorem ipsum’ will
                                        uncover many web sites still in their
                                        infancy. Various versions have evolved
                                        over the years, sometimes by accident,
                                        sometimes on purpose injected humour and
                                        the like
                                      </p>
                                      <ol>
                                        <li>
                                          Ut enim ad minima veniam, quis nostrum
                                          exercitationem ullam corporis suscipit
                                          laboriosam, nisi ut aliquid ex ea
                                          commodi consequatur
                                        </li>
                                        <li>
                                          Quis autem vel eum iure reprehenderit
                                          qui in ea voluptate velit esse quam
                                          nihil molestiae consequatur, vel illum
                                          qui dolorem eum fugiat quo voluptas
                                          nulla pariatur
                                        </li>
                                        <li>
                                          Et harum quidem rerum facilis est et
                                          expedita distinctio. Nam libero
                                          tempore, cum soluta nobis est eligendi
                                          optio cumque nihil impedit quo minus
                                          id quod maxime placeat facere
                                          possimus, omnis voluptas assumenda
                                          est, omnis dolor repellendus.
                                        </li>
                                        <li>
                                          Temporibus autem quibusdam et aut
                                          officiis debitis aut rerum
                                          necessitatibus saepe eveniet ut et
                                          voluptates repudiandae sint et
                                          molestiae non recusandae.
                                        </li>
                                      </ol>
                                      <p>
                                        There are many variations of passages of
                                        Lorem Ipsum available, but the majority
                                        have suffered alteration in some form,
                                        by injected humour, or randomised words
                                        which don’t look even slightly
                                        believable. If you are going to use a
                                        passage of Lorem Ipsum, you need to be
                                        sure there isn’t anything embarrassing
                                        hidden in the middle of text.
                                      </p>
                                      <h3>
                                        Why do we use it?
                                        <br />
                                      </h3>
                                      <p>
                                        Contrary to popular belief, Lorem Ipsum
                                        is not simply random text. It has roots
                                        in a piece of classical Latin literature
                                        from 45 BC, making it over 2000 years
                                        old. Richard McClintock, a Latin
                                        professor at Hampden-Sydney College in
                                        Virginia, looked up one of the more
                                        obscure Latin words
                                      </p>
                                      <p>
                                        All the Lorem Ipsum generators on the
                                        Internet tend to repeat predefined
                                        chunks as necessary, making this the
                                        first true generator on the Internet. It
                                        uses a dictionary of over 200 Latin
                                        words, combined with a handful of model
                                        sentence structures, to generate Lorem
                                        Ipsum which looks reasonable. The
                                        generated Lorem Ipsum is therefore
                                        always free from repetition, injected
                                        humour, or non-characteristic words etc.
                                      </p>
                                      <ul>
                                        <li>
                                          Lorem ipsum dolor sit amet,
                                          consectetur adipiscing elit, sed do
                                          eiusmod tempor incididunt ut labore et
                                          dolore magna aliqua. Ut enim ad minim
                                          veniam, quis nostrud exercitation
                                          ullamco laboris nisi ut aliquip ex ea
                                          commodo consequat.
                                        </li>
                                        <li>
                                          Duis aute irure dolor in reprehenderit
                                          in voluptate velit esse cillum dolore
                                          eu fugiat nulla pariatur. Excepteur
                                          sint occaecat cupidatat non proident,
                                          sunt in culpa qui officia deserunt
                                          mollit anim id est laborum.
                                        </li>
                                        <li>
                                          No one rejects, dislikes, or avoids
                                          pleasure itself, because it is
                                          pleasure, but because those who do not
                                          know how to pursue pleasure rationally
                                          encounter consequences that are
                                          extremely painful.
                                        </li>
                                      </ul>
                                    </div>
                                    <p className="form-row validate-required">
                                      <label className="woocommerce-form__label woocommerce-form__label-for-checkbox checkbox">
                                        <input
                                          type="checkbox"
                                          className="woocommerce-form__input woocommerce-form__input-checkbox input-checkbox"
                                          name="terms"
                                          id="terms"
                                          checked={acceptTAC}
                                          onChange={(e) =>
                                            setAcceptTAC(e.target.checked)
                                          }
                                        />
                                        <span className="woocommerce-terms-and-conditions-checkbox-text">
                                          I have read and agree to the website{" "}
                                          <a
                                            href="#https://klbtheme.com/bacola/terms-and-conditions/"
                                            className="woocommerce-terms-and-conditions-link"
                                            // target="_blank"
                                          >
                                            terms and conditions
                                          </a>
                                        </span>
                                        &nbsp;
                                        <abbr
                                          className="required"
                                          title="required"
                                        >
                                          {" "}
                                          *{" "}
                                        </abbr>
                                      </label>
                                    </p>
                                  </div>
                                  <button
                                    type="button"
                                    className="button alt wp-element-button"
                                    name="woocommerce_checkout_place_order"
                                    id="place_order"
                                    value="Place order"
                                    data-value="Place order"
                                    onClick={() => handleOrderSubmit()}
                                  >
                                    Place order
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Checkout;
