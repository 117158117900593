import { Link } from "react-router-dom";

const FooterContacts = () =>{
    return (
        <div className="footer-contacts">
                <div className="container">

                   

                    <div className="column column-right">
                        <div className="site-mobile-app">
                            <div className="app-content">
                                {/* <h6 className="entry-title">Download App on Mobile :</h6> */}
                                {/* <span>15% discount on your first purchase</span> */}
                            </div>
                            <div className="app-buttons">
                                {/* <Link href="#https://play.google.com/store" className="google-play">
                                    <img src="/images/google-play.png" alt="app" />
                                </Link>
                                <Link href="#https://www.apple.com/app-store/" className="google-play">
                                    <img src="/images/app-store.png" alt="app" />
                                </Link> */}
                            </div>
                        </div>

                        <div className="site-social">
                            <ul>
                                {/* <li><Link href="#https://www.facebook.com/"><i className="klbth-icon-facebook"></i></Link>
                                </li>
                                <li><Link href="#https://twitter.com/" ><i className="klbth-icon-twitter"></i></Link></li>
                                <li><Link href="#https://www.instagram.com/"><i className="klbth-icon-instagram"></i></Link>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>        
    );
}


export default FooterContacts;