export const isLogin = () => {
  let token = localStorage.getItem("token");
  let is_authenticated = localStorage.getItem("is_authenticated");

  if (!token || !is_authenticated || is_authenticated === "0") {
    return false;
  }
  return true;
};

export const randomString = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const convertToSlug = (str) => {
  return str
    ?.toLowerCase()
    ?.replace(/ /g, "-")
    ?.replace(/[^\w-]+/g, "");
};

export function decodeHTMLEntities(text) {
  var entities = [
    ["amp", "&"],
    ["apos", "'"],
    ["#x27", "'"],
    ["#x2F", "/"],
    ["#39", "'"],
    ["#47", "/"],
    ["lt", "<"],
    ["gt", ">"],
    ["nbsp", " "],
    ["quot", '"'],
  ];

  if (text?.length > 0) {
    for (var i = 0, max = entities?.length; i < max; ++i)
      text = text.replace(
        new RegExp("&" + entities[i][0] + ";", "g"),
        entities[i][1]
      );

    return text;
  } else {
    return "";
  }
}

export const handleAuthFailure = (error, reject) => {
  if (
    error?.response.status === 401 &&
    error?.response.data.message === "Unauthenticated."
  ) {
    localStorage.removeItem("token");
    localStorage.removeItem("is_authenticated");
    if (window.location.pathname !== "/login") {
      // window.location.href = "/login";
    }
  } else {
    reject(error);
  }
};
