import axios from "axios";
import { handleAuthFailure } from "../utils/constant";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const getAllCountry = async () => {
  let token = await localStorage.getItem("token");
  let headers = {
    Authorization: `Bearer ${token}`,
  };
  return new Promise(function (resolve, reject) {
    axios
      .get("/customer/country", { headers: headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        handleAuthFailure(error, reject);
      });
  });
};

export { getAllCountry };
